import React, {useState} from 'react';
import './../Registration/login.scss'
import Api from '../../api/index.js';
import {Link, useHistory} from 'react-router-dom';
import {FormInput} from "../../components/FormInput/FormInput";
import * as Yup from "yup";
import {Form, Formik} from "formik";

const initialValue = {
  email: "",
  password: "",
};

const RegisterSchema = Yup.object().shape({
  email: Yup.string().required("This field is required!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("This field is required!"),
});

const Login = ({setAuth}) => {
  const history = useHistory();
  const [serverMessage, setServerMessage] = useState(null);

  const onSubmit = (data) => {
    Api.postData("auth/users/token/", data)
      .then((res) => {
        setAuth({
          ...res.data,
          email: data?.email
        });
        history.push("/profile");
      })
      .catch((error) => {
        console.error(error);
        setServerMessage(error?.response?.data?.detail);
      });
  };

  return (
    <div className="login">
      <div className="login-form text-white px-3">

        <p className="login-form-submit__title">
          Log in
        </p>

        {serverMessage &&
          <p className="text-warning">
            {serverMessage}
          </p>
        }

        <Formik
          initialValues={initialValue}
          validationSchema={RegisterSchema}
          onSubmit={onSubmit}
        >
          {({values, errors, touched,  handleChange}) => (
            <Form style={{width: "425px"}}>
              <FormInput
                label={<span className="text-white">Email*</span>}
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                color="warning"
                InputProps={{
                  style:{
                    color: "white",
                  }
                }}
                error={errors.email && touched.email}
                helperText={errors.email}
              />
              <FormInput
                label={<span className="text-white">Password*</span>}
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                color="warning"
                InputProps={{
                  style:{
                    color: "white",
                  }
                }}
                error={errors.password && touched.password}
                helperText={errors.password}
              />

              <div className="login-form-submit-socials">
                <a href="/" className="login-form-submit-socials__forgot">Forgot Password?</a>
              </div>

              <div className="login-form-submit-logIn">

                <span className="me-1">New here?</span>
                <Link to="/registration"> Register</Link>

                <button
                  type='submit'
                  className="login-form-submit-logIn__btn"
                  style={{marginLeft: '58px'}}
                >
                  Log in
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;