import React, { useState, useEffect, useRef } from "react";
import testImage from "./../../assets/images/testImage.png";
import "./StimuliPresentation.scss";

const StimuliPresentation = ({
    stimuli,
    onFinish,
    onStimulusChange,
    fullscreenActive,
    firstDetectionCaptured,
    presentationRef,
    enterFullScreen,
    setFullscreenActive,
    stopVideoCapture,
}) => {
    const [currentStimulusIndex, setCurrentStimulusIndex] = useState(0);
    const [timer, setTimer] = useState(null);
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    useEffect(() => {
        function handleFullScreenChange() {
            if (!document.fullscreenElement) {
                // Exited full screen mode
                setFullscreenActive(false);
                console.log("capturestop2");
                stopVideoCapture();
                sendIframeToBack(); // Call the function to send iframe to the back
            }
        }

        document.addEventListener("fullscreenchange", handleFullScreenChange);

        return () => {
            document.removeEventListener(
                "fullscreenchange",
                handleFullScreenChange
            );
        };
    }, [setFullscreenActive, stopVideoCapture]);

    useEffect(() => {
        const media = document.querySelector(".media-content");
        if (media) {
            // Get the natural dimensions of the media
            const mediaWidth = media.naturalWidth || media.videoWidth;
            const mediaHeight = media.naturalHeight || media.videoHeight;

            // Compare aspect ratio of media to aspect ratio of the window
            if (mediaWidth > mediaHeight) {
                // Landscape
                media.style.width = "100%";
                media.style.height = "auto";
                media.style.maxHeight = "none";
            } else {
                // Portrait or square
                media.style.width = "auto";
                media.style.height = "100vh";
                media.style.maxWidth = "none";
            }
        }
    }, [currentStimulusIndex]); // Depend on the index to re-apply when the media changes

    useEffect(() => {
        if (fullscreenActive) {
            enterFullScreen();
        }
    }, [fullscreenActive, enterFullScreen]);

    useEffect(() => {
        if (presentationRef.current && !fullscreenActive) {
            enterFullScreen();
        }
    }, [fullscreenActive, enterFullScreen, presentationRef]);

    useEffect(() => {
        const currentStimulus = stimuli[currentStimulusIndex];
        onStimulusChange(currentStimulus.title);

        if (
            currentStimulus?.category?.title?.toLowerCase() === "video" &&
            currentStimulus?.file
        ) {
            const videoElement = videoRef.current;
            const onVideoPlay = () => {
                startTimer(currentStimulus.seconds * 1000);
            };

            videoElement.addEventListener("play", onVideoPlay);

            return () => {
                videoElement.removeEventListener("play", onVideoPlay);
                clearTimeout(timer);
            };
        } else if (stimuli && stimuli.length > 0) {
            startTimer(currentStimulus.seconds * 1000);
        }
    }, [currentStimulusIndex, stimuli, onStimulusChange]);

    useEffect(() => {
        const currentStimulus = stimuli[currentStimulusIndex];
        onStimulusChange(currentStimulus.title);

        if (
            currentStimulus?.category?.title?.toLowerCase() === "audio" &&
            currentStimulus?.file
        ) {
            const playAudio = async () => {
                if (audioRef.current) {
                    try {
                        await audioRef.current.play();
                    } catch (e) {
                        console.error("Error playing audio:", e);
                    }
                }
            };

            playAudio();
        }

        if (currentStimulus?.seconds) {
            startTimer(currentStimulus.seconds * 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [currentStimulusIndex, stimuli, onStimulusChange]);

    const displayNextStimulus = () => {
        if (currentStimulusIndex < stimuli.length - 1) {
            setCurrentStimulusIndex(currentStimulusIndex + 1);
        } else {
            console.log("capturestop1");
            console.log(currentStimulusIndex);
            stopVideoCapture();
            onFinish();
        }
    };

    const startTimer = (duration) => {
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            displayNextStimulus();
        }, duration);

        setTimer(newTimer);
    };

    const sendIframeToBack = () => {
        const iframe = document.querySelector("iframe");
        if (iframe) {
            iframe.style.zIndex = "-1";
            console.log("iframe sent to the back"); // Send the iframe to the back
        }
    };

    if (!stimuli || stimuli.length === 0) {
        return null;
    }

    const currentStimulus = stimuli[currentStimulusIndex];

    return (
        <div>
            <div ref={presentationRef}>
                {currentStimulus?.category?.title?.toLowerCase() ===
                    "image" && (
                    <img
                        src={
                            currentStimulus?.file ||
                            "https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
                        }
                        alt="testImage"
                        className="media-content"
                    />
                )}

                {currentStimulus?.category?.title?.toLowerCase() ===
                    "video" && (
                    <video
                        ref={videoRef}
                        width="720"
                        height="400"
                        autoPlay
                        style={{ borderRadius: 20 }}
                        className="media-content"
                    >
                        <source src={currentStimulus?.file} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}

                {currentStimulus?.category?.title?.toLowerCase() ===
                    "audio" && (
                    <div>
                        <img
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                                height: 400,
                            }}
                            src={testImage}
                            alt="Audio"
                            className="media-content"
                        />
                        <audio
                            ref={audioRef}
                            autoPlay
                            controlsList="nodownload"
                            controls
                            style={{ opacity: 0, visibility: "hidden" }}
                            key={currentStimulusIndex}
                        >
                            <source
                                src={currentStimulus?.file}
                                type="audio/mpeg"
                            />
                        </audio>
                    </div>
                )}

                {!currentStimulus?.file && (
                    <img
                        src="https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
                        alt="testImage"
                        className="media-content"
                    />
                )}
            </div>
        </div>
    );
};

export default StimuliPresentation;
