const token = localStorage.getItem('cortexToken')

export const _auth = (method, debit) => {

    const params = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": method,
        "params": {
            "clientId": "ZsMXJvVqaOR4Pq5QUY46Djw4wXNjni3MK4NBihh3",
            "clientSecret": "7OwHHGELxD9tQLoo2J5g1kswqKcjkoD51XoX8qGcjBSytuqt3gtg9Yuq0f1KX6fRNzJIn3DVGZ3FNQ7WflfHSMvWr4YKhldprljskJBAFdHuo8xYpIgoEdlzZlMQzLS8",
            ...(debit && { 'debit': debit })
        }
    }
    return JSON.stringify(params)
}


export function getAccess() {
    return _auth('requestAccess')
}

export function getCortexToken(debit) {
    return _auth('authorize', debit)
}

export function hasAccessRight() {
    return _auth('hasAccessRight')
}

export function queryHeadsets() {
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "queryHeadsets"
    }
    return JSON.stringify(query)
}

export function queryHeadsetsById(id) {
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "queryHeadsets",
        "params": {
            "id": id
        }
    }
    return JSON.stringify(query)
}

export function connectHeadset(id) {
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "controlDevice",
        "params": {
            "command": "connect",
            "headset": id
        }
    }
    return JSON.stringify(query)
}

export function disconnectHeadset(id) {
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "controlDevice",
        "params": {
            "command": "disconnect",
            "headset": id
        }
    }
    return JSON.stringify(query)
}

export function refreshHeadset() {
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "controlDevice",
        "params": {
            "command": "refresh"
        }
    }
    return JSON.stringify(query)
}

export function createSession(id) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "createSession",
        "params": {
            "cortexToken": token,
            "headset": id,
            "status": "active"
        }
    }
    return JSON.stringify(query)
}

export function closeSession(id) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "updateSession",
        "params": {
            "cortexToken": token,
            "session": id,
            "status": "close"
        }
    }
    return JSON.stringify(query)
}

export function subscribe(id) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "subscribe",
        "params": {
            "cortexToken": token,
            "session": id,
            "streams": ["pow"]
        }
    }
    return JSON.stringify(query)
}

export function subscribeDevice(id) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "subscribe",
        "params": {
            "cortexToken": token,
            "session": id,
            "streams": ["dev"]
        }
    }
    return JSON.stringify(query)
}

export function getLicenseInfo() {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "getLicenseInfo",
        "params": {
            "cortexToken": token
        }
    }
    return JSON.stringify(query)
}


export function createRecord(id, title) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "createRecord",
        "params": {
            "cortexToken": token,
            "session": id,
            "title": title
        }
    }
    return JSON.stringify(query)
}

export function stopRecord(id) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "stopRecord",
        "params": {
            "cortexToken": token,
            "session": id,
        }
    }
    return JSON.stringify(query)
}

export function exportRecord(recordIds) {
    const token = localStorage.getItem('cortexToken')
    const query = {
        "id": 1,
        "jsonrpc": "2.0",
        "method": "exportRecord",
        "params": {
            "cortexToken": token,
            "folder": '/tmp/csv2',
            "format": "CSV",
            "recordIds": recordIds,
            "streamTypes": [
                "EEG",
                "MOTION",
                "PM",
                "BP"
            ],
            "version": "V2"
        }
    }
    return JSON.parse(query)
}