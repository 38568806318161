import React from "react";

const HeadsetPoint = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.1408"
        cy="14.5441"
        r="13.6304"
        fill="#ADD57F"
        stroke="#99C16B"
        stroke-width="1.43478"
      />
      <circle cx="15.1407" cy="14.544" r="7.17391" fill="#5A6A6E" />
    </svg>
  );
};

export default HeadsetPoint;
