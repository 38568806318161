import React from "react";

function VideoBasedEmotionAnalysisIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
        >
            <g data-name="Layer 2">
                <path
                    fill="white"
                    d="M28.833 26.5H25a3.476 3.476 0 00-6.673 0H2a1 1 0 000 2h16.33a3.476 3.476 0 006.67 0h3.83a1 1 0 000-2zM21.667 29a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM27.86 1H4.14A3.143 3.143 0 001 4.14v15.72A3.143 3.143 0 004.14 23h23.72A3.143 3.143 0 0031 19.86V4.14A3.143 3.143 0 0027.86 1zM29 19.86A1.141 1.141 0 0127.86 21H4.14A1.141 1.141 0 013 19.86V4.14A1.141 1.141 0 014.14 3h23.72A1.141 1.141 0 0129 4.14z"
                ></path>
                <path
                    fill="white"
                    d="M21.53 11.152l-8-5A1 1 0 0012 7v10a1 1 0 001.53.848l8-5a1 1 0 000-1.7zM14 15.2V8.8l5.113 3.2z"
                ></path>
            </g>
        </svg>
    );
}

export default VideoBasedEmotionAnalysisIcon;
