import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Grid } from "@mui/material";
import "./dashboard.scss";
import LogOutModal from "../LogoutModal/LogoutModal";
import logout from "../../assets/images/SVG/LogOut.svg";
import { Account } from "../../assets/images/SVG/Account";
import { TestIcon } from "../../assets/images/SVG/TestIcon";
import { TestResultsIcon } from "../../assets/images/SVG/TestResultIcon";
import { HeadsetIcon } from "../../assets/images/SVG/HeadsetIcon";
import { LogoIcon } from "../../assets/images/SVG/LogoIcon";
import { InstructionsIcon } from "../../assets/images/SVG/InstructionsIcon";
import EmotionAnalysisIcon from "../../assets/images/SVG/EmotionAnalysisIcon";
import VideoBasedEmotionAnalysisIcon from "../../assets/images/SVG/VideoBasedEmotionAnalysisIcon";

const Dashboard = () => {
    const [logOutModal, setLogOutModal] = useState(false);

    const logoutModal = () => setLogOutModal((prev) => !prev);

    const router = useLocation();
    let link = router.pathname;

    return (
        <div className="dashboard overflow-auto">
            <Grid
                container
                alignItems="center"
                justifyContent="left"
                className="p-3"
            >
                <LogoIcon />
                <span className="logo-title">Neuro Project</span>
            </Grid>

            <div className="mt-4">
                <NavLink
                    className="dashboard__link"
                    to="/profile"
                    activeClassName="dashboard__link-active"
                >
                    <Account color={link === "/profile" ? "#fff" : "#ccc"} />
                    <p className="dashboard__link-title">Account</p>
                </NavLink>

                <NavLink
                    className="dashboard__link"
                    to="/tests-list"
                    activeClassName="dashboard__link-active"
                >
                    <TestIcon color={link === "/results" ? "#fff" : "#ccc"} />
                    <p className="dashboard__link-title">Tests</p>
                </NavLink>

                <NavLink
                    className="dashboard__link"
                    to="/emotion-analysis"
                    activeClassName="dashboard__link-active"
                >
                    <EmotionAnalysisIcon
                        color={link === "/emotion-analysis" ? "#fff" : "#ccc"}
                    />
                    <p className="dashboard__link-title">
                        Facial Emotion Analysis
                    </p>
                </NavLink>

                <NavLink
                    className="dashboard__link"
                    to="/video-based-emotion-analysis"
                    activeClassName="dashboard__link-active"
                >
                    <VideoBasedEmotionAnalysisIcon
                        color={
                            link === "/video-based-emotion-analysis"
                                ? "#fff"
                                : "#ccc"
                        }
                    />
                    <p className="dashboard__link-title">
                        Video-Based FEA
                    </p>
                </NavLink>

                <NavLink
                    className="dashboard__link"
                    to="/results"
                    activeClassName="dashboard__link-active"
                >
                    <TestResultsIcon
                        color={link === "/results" ? "#fff" : "#ccc"}
                    />
                    <p className="dashboard__link-title">Test results</p>
                </NavLink>
                <NavLink
                    className="dashboard__link"
                    to="/headset"
                    activeClassName="dashboard__link-active"
                >
                    <HeadsetIcon
                        color={link === "/headset" ? "#fff" : "#ccc"}
                    />
                    <p className="dashboard__link-title">Headset connection</p>
                </NavLink>

                <NavLink
                    className="dashboard__link"
                    to="/instructions"
                    activeClassName="dashboard__link-active"
                >
                    <InstructionsIcon
                        color={link === "/instructions" ? "#fff" : "#ccc"}
                    />
                    <p className="dashboard__link-title">Instructions</p>
                </NavLink>

                <div
                    className="d-flex align-items-center m-3 py-5"
                    onClick={logoutModal}
                >
                    <img src={logout} alt="logout" />
                    <p
                        className="logoutText m-0 px-3"
                        style={{ cursor: "pointer" }}
                    >
                        Log out
                    </p>
                </div>
            </div>
            <LogOutModal open={logOutModal} setLogOutModal={logoutModal} />
        </div>
    );
};

export default Dashboard;
