import React, { useRef, useState, useEffect } from "react";
import * as faceapi from "face-api.js";
import axios from "axios";
import FacialEmotionAnalysisIcon from "../../assets/images/SVG/FacialEmotionAnalysisIcon";
import { toast, ToastContainer } from "react-toastify";
import Api from "../../api";
import * as XLSX from "xlsx";
import { useCallback } from "react";
import "./VideoBasedEmotionAnalysis.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ReactPlayer from "react-player";

const VideoBasedEmotionAnalysis = () => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState({ started: false, pc: 0 });
    const [msg, setMsg] = useState(null);
    // Additional state to store the frames
    const [frames, setFrames] = useState([]);
    const [shouldExtractFrames, setShouldExtractFrames] = useState(false);
    const [extractionComplete, setExtractionComplete] = useState(false);
    const [emotionData, setEmotionData] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const { createCanvas, loadImage } = require("canvas");
    const [fileName, setFileName] = useState(null);

    const loadModels = async () => {
        await Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]);
    };

    // Function to handle frame extraction
    const extractFrames = (videoElement) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const duration = videoElement.duration;
        const fps = 2; // As we want a frame every 0.5 seconds
        let currentTime = 0;

        videoElement.addEventListener("seeked", function () {
            // Draw the video frame to the canvas
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Create a data URL and add it to the frames state
            const dataUrl = canvas.toDataURL("image/jpeg");
            setFrames((prevFrames) => [...prevFrames, dataUrl]);

            // Seek to the next time point
            currentTime += 0.1;
            console.log("Current time:", currentTime);
            if (currentTime <= duration) {
                videoElement.currentTime = currentTime;
            } else {
                // Finished extracting frames
                console.log("Finished extracting frames");
                setExtractionComplete(true);
            }
        });

        // Set the canvas size
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        // Start extracting frames
        videoElement.currentTime = currentTime;
    };

    function handleFileChange(e) {
        const selectedFile = e.target.files[0];
        setFileName(selectedFile.name);
        if (selectedFile) {
            // Set the URL for the video to be played by ReactPlayer
            const url = URL.createObjectURL(selectedFile);
            setVideoUrl(url);

            // Set the file state
            setFile(selectedFile);

            setShouldExtractFrames(true);
        }
    }
    // // Renamed and refactored handleUpload to accept a file argument
    // function uploadFile(fileToUpload) {
    //     const fd = new FormData();
    //     fd.append("file", fileToUpload);

    //     setMsg("Uploading...");
    //     setProgress({ started: true, pc: 0 });

    //     axios
    //         .post("http://httpbin.org/post", fd, {
    //             onUploadProgress: (progressEvent) => {
    //                 const percentCompleted =
    //                     (progressEvent.loaded / progressEvent.total) * 100;
    //                 setProgress({ started: true, pc: percentCompleted });
    //             },
    //             headers: {
    //                 "Custom-Header": "value",
    //             },
    //         })
    //         .then((res) => {
    //             setMsg("Upload Successful");
    //             setShouldExtractFrames(true);
    //         })
    //         .catch((err) => {
    //             setMsg("Upload Failed");
    //             console.error(err);
    //         });
    // }

    // Start extracting frames when the user presses the upload button and shouldExtractFrames is true
    useEffect(() => {
        if (shouldExtractFrames && file) {
            const video = document.createElement("video");
            video.preload = "metadata";
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                extractFrames(video);
                setMsg("Extracting frames and analyzing emotions...");
            };
        }
    }, [shouldExtractFrames, file]);

    function handleAnalyze(selectedFile) {
        // Extract frames from the video immediately
        // Create a video element for extracting frames
        const video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(selectedFile);
        video.onloadedmetadata = () => {
            extractFrames(video);
        };
    }

    // Run analyzeImageEmotions when frame extraction is complete
    useEffect(() => {
        const calculateAverageEmotions = () => {
            const emotionSums = {};
            let emotionCounts = {};

            emotionData.forEach(({ emotions }) => {
                emotions.forEach(({ emotion, probability }) => {
                    if (!emotionSums[emotion]) {
                        emotionSums[emotion] = 0;
                        emotionCounts[emotion] = 0;
                    }
                    emotionSums[emotion] += probability;
                    emotionCounts[emotion]++;
                });
            });

            const emotionAverages = Object.keys(emotionSums).reduce(
                (acc, emotion) => {
                    acc[emotion] =
                        emotionSums[emotion] / emotionCounts[emotion];
                    return acc;
                },
                {}
            );

            console.log(emotionAverages);
            return emotionAverages;
        };

        // Function to analyze an array of images and return emotion data
        const analyzeImageEmotions = async (imageArray) => {
            // Ensure models are loaded
            await loadModels();

            // This will hold the emotion analysis data for all images
            let emotionsData = [];

            for (const [index, image] of imageArray.entries()) {
                // Create an HTML element for the image
                let img = new Image();
                img.src = image;

                // Wait for the image to load before performing analysis
                await new Promise((resolve) => {
                    img.onload = resolve;
                });

                // Detect emotions on the loaded image
                const detections = await faceapi
                    .detectSingleFace(
                        img,
                        new faceapi.TinyFaceDetectorOptions()
                    )
                    .withFaceLandmarks()
                    .withFaceExpressions();

                // If a face is detected, process the emotions
                if (detections) {
                    const detectedEmotions = detections.expressions;
                    const emotionData = {
                        snapshot: index + 1,
                        emotions: Object.keys(detectedEmotions).map(
                            (emotion) => ({
                                emotion,
                                probability: detectedEmotions[emotion],
                            })
                        ),
                        // // Assuming the stimulus title is to be determined or passed in some way
                        // stimulus: "Unknown", // Placeholder, you need to define how to get this
                    };

                    emotionsData.push(emotionData);
                }
            }
            console.log(emotionsData);
            setEmotionData(emotionsData);
            setAnalysisComplete(true);
            calculateAverageEmotions();
        };

        if (extractionComplete && frames.length > 0) {
            // Reset states if needed
            analyzeImageEmotions(frames);

            setShouldExtractFrames(false);
            setExtractionComplete(false);
        }
    }, [extractionComplete, frames, emotionData]);

    const sendData = async (emotionData) => {
        console.log("Sending Emotion Data:", emotionData);

        const userData = JSON.parse(localStorage.getItem("auth"));

        // Filter out rows with stimulus equal to null
        const filteredEmotionData = emotionData.filter(
            (data) => data.stimulus !== "null"
        );

        if (filteredEmotionData.length > 0) {
            const excelData = filteredEmotionData.map((data) => ({
                Snapshot: data.snapshot,
                time: data.snapshot * 0.1,
                ...data.emotions.reduce((acc, emotion) => {
                    acc[emotion.emotion] = emotion.probability;
                    return acc;
                }, {}),
            }));

            // Create a new workbook and add a worksheet with the excelData
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(excelData);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Emotions");

            // Convert workbook to a binary buffer
            const binaryBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            // Create form data for sending
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([binaryBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                "EmotionData.xlsx"
            );

            formData.append("test_type", "video");
            formData.append("status", true);
            formData.append("description", fileName);

            try {
                const response = await axios.post(
                    "/api/results/create/",
                    formData,
                    {
                        headers: {
                            Authorization: `JWT ${userData?.access}`,
                        },
                    }
                );
                console.log("Emotions data sent successfully", response.data);
                setMsg("Emotions data sent successfully");
                // Assume frames is an array of data URLs and emotionData is the array shown in the screenshot
                addEmotionResultsToFrames(frames, emotionData).then(
                    (newFramesWithEmotions) => {
                        // Update your state with these new frames

                        console.log(frames);
                        handleDownloadFrames(newFramesWithEmotions);
                    }
                );
            } catch (error) {
                console.error("Error sending emotions data", error);
                // console.log(frames);
                // addEmotionResultsToFrames(frames, emotionData).then(
                //     (newFramesWithEmotions) => {
                //         // Update your state with these new frames

                //         console.log(frames);
                //         handleDownloadFrames(newFramesWithEmotions);
                //     }
                // );
            }
        }
    };

    useEffect(() => {
        // Check if emotionData is populated before trying to send it
        if (emotionData.length > 0) {
            sendData(emotionData); // This now sends the analyzed data
        }
    }, [emotionData]);

    // Function to send iframes to the back
    const sendIframeToBack = () => {
        const iframes = document.querySelectorAll("iframe");
        iframes.forEach((iframe) => {
            iframe.style.zIndex = -1;
        });
    };

    // Add an event listener to catch iframes that pop up unexpectedly
    useEffect(() => {
        const iframeHandler = (event) => {
            sendIframeToBack();
        };

        window.addEventListener("DOMNodeInserted", iframeHandler);

        return () => {
            window.removeEventListener("DOMNodeInserted", iframeHandler);
        };
    }, []);

    function handleDownloadFrames(frames, emotionData) {
        const zip = new JSZip();
        // Loop through the emotionData instead of frames
        emotionData.forEach((emotion, index) => {
            const frameIndex = emotion.snapshot - 1; // Adjust if your indexing is different
            if (frames[frameIndex]) {
                const frame = frames[frameIndex];
                // Assuming frames are base64 encoded images
                const base64Data = frame.split("base64,")[1];
                // Use emotion.snapshot to name the files
                zip.file(`frame${emotion.snapshot}.jpg`, base64Data, {
                    base64: true,
                });
            }
        });
        console.log("Preparing download for frames with emotion snapshots");
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "frames_with_emotion_snapshots.zip");
        });
    }

    async function addEmotionResultsToFrames(frames, emotionData) {
        const modifiedFrames = [];
        let emotionDataIndex = 0; // Track the current index of emotion data being processed

        for (let frameIndex = 0; frameIndex < frames.length; frameIndex++) {
            if (emotionDataIndex >= emotionData.length) {
                // If there are no more emotion data entries to process, stop processing further frames
                break;
            }

            const frame = frames[frameIndex];
            const currentEmotionData = emotionData[emotionDataIndex];

            // Assuming emotionData includes a 'snapshot' property indicating the frame it corresponds to
            if (currentEmotionData.snapshot === frameIndex + 1) {
                // Adjust if your frame indexing differs
                const emotions = currentEmotionData.emotions;

                const image = await loadImage(frame);
                const canvas = createCanvas(
                    image.width + (image.height * 9) / 16,
                    image.height
                );
                const ctx = canvas.getContext("2d");

                // Draw the original image
                ctx.drawImage(image, (image.height * 9) / 16, 0);

                // Draw the white block for text
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, (image.height * 9) / 16, image.height);

                // Set text properties
                ctx.fillStyle = "#000000";
                ctx.font = "60px Arial";
                ctx.textAlign = "left";
                ctx.textBaseline = "middle";

                // Calculate the total height of the text block
                const lineHeight = 70; // Increased line height for bigger text
                const totalTextHeight = emotions.length * lineHeight;

                // Calculate the starting Y position to center the text block vertically
                const startY = (canvas.height - totalTextHeight) / 2;

                // Write the emotion results
                emotions.forEach((emotionObj, index) => {
                    ctx.fillText(
                        `${emotionObj.emotion}: ${(
                            emotionObj.probability * 100
                        ).toFixed(2)}%`,
                        10,
                        startY + lineHeight * index
                    );
                });

                // Convert canvas to Data URL
                const dataUrl = canvas.toDataURL();
                modifiedFrames.push(dataUrl);

                emotionDataIndex++; // Move to the next emotion data entry for processing
            }
            // If there's no matching emotion data for a frame, you can choose to skip it or add unmodified
            // else {
            //     // Optionally, add the unmodified frame if you want to keep frames without detected emotions
            //     modifiedFrames.push(frame);
            // }
        }

        return modifiedFrames;
    }

    return (
        <div className="text-center fade-in text-black main">
            <h1 className="title">Upload Video for Facial Emotion Analysis</h1>
            {/* The actual file input: hidden but functional */}
            <input
                id="fileInput"
                className="file-input"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }} // This hides the input
            />
            {file && (
                <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    width="40%"
                    height="auto"
                    playing={false}
                    // You can add more props as needed
                />
            )}
            {msg && <span>{msg}</span>}

            <button
                className="btn text-white mt-3 mx-auto"
                style={{
                    backgroundColor: !file ? "#1BBDD4" : "#D3D3D3",
                }}
                onClick={() => document.getElementById("fileInput").click()} // This triggers the click on the actual file input
                disabled={!!file}
            >
                Choose File
            </button>
            {/* {shouldExtractFrames && (
                <button
                    className="btn text-white mt-3 mx-auto"
                    onClick={handleAnalyze}
                >
                    Analyze
                </button>
            )}
            {analysisComplete && (
                <button
                    className="btn text-white mt-3 mx-auto"
                    style={{
                        backgroundColor: file ? "#1BBDD4" : "#D3D3D3",
                    }}
                    onClick={() => {
                        sendData(emotionData);
                    }}
                    disabled={!file}
                >
                    Send Results
                </button>
            )} */}
            {progress.started && (
                <progress max="100" value={String(progress.pc)}></progress>
            )}
            <div>
                {/* {frames.map((frame, index) => (
                    <img
                        key={index}
                        src={frame}
                        alt={`Frame ${index}`}
                        height="50vh"
                        weight="auto"
                    />
                ))} */}
            </div>
        </div>
    );
};

export default VideoBasedEmotionAnalysis;
