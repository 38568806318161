import React from 'react';
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {RefreshIcon} from "../../../assets/images/SVG/RefreshIcon";
import {HeadsetIcon} from "../../../assets/images/SVG/Headset";

import './styles.scss'
import EmotivIcon from '../../../assets/emotivdevice.png'

export const HeadsetsLists = ({data, getHeadset, disconnect, connect, battery}) => {
  
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className='modal-device'
      direction={"column"}>
      <Grid
        container
        direction="row"
        className="w-100 mb-3"
        justifyContent="space-between"
        alignItems="center"
      >
       <span className='modal-device__title'>
          Available devices
       </span>
        
        <IconButton onClick={()=>getHeadset()}>
          <RefreshIcon fill={"#CCCCCC"} size={18}/>
        </IconButton>
      </Grid>
      <Grid className='modal-device' item container xs={4}>
        {data?.map((obj, index) => (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            key={index}
            className='modal-device__row'
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              className='modal-device__display_contents'
              xs={12}
              alignItems="center">
                <Grid className='modal-device__display_contents' container item xs={7} direction="row" justifyContent="left" alignItems="center">
                 
                 <div className='mr-1'> <img className='modal-device__icon' src={EmotivIcon} ></img></div>
              
                  <div className='device-info'>
                    <span className='device-info__id'>{obj.id}</span>
                    <span className='device-info__battery'>Battery: {battery ? `${battery}%` : 'N/A'}</span>
                  </div>
                </Grid>
              <Grid className='modal-device__display_contents' item xs={5}>
                {obj.status === "connected" ?
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#1BBDD4", width: 110}}
                    onClick={() => disconnect(obj.id)}
                  >
                    Disconnect
                  </Button> :
                  <Button
                    variant="outlined"
                    style={{border: "1px solid #1BBDD4", color: "#1BBDD4"}}
                    onClick={() => connect(obj.id)}
                  >
                    Connect
                  </Button> }
              </Grid>
              </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};