import close from "./../../assets/images/close.svg";
import "./FacialEmotionAnalysis.scss";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import Api from "../../api";
import { toast, ToastContainer } from "react-toastify";
import StimuliPresentation from "./StimuliPresentation";

const FacialEmotionAnalysisModal = ({
    closeShowModal,
    data,
    startVideoCapture,
    stopVideoCapture,
    sendData,
    emotionData,
    onStimulusChange,
    currentStimulusTitle,
    firstDetectionCaptured,
    setFirstStimulus,
    startVideoCaptureAndCheckCamera,
}) => {
    const [modalStage, setModalStage] = useState("initial");
    const [formData, setFormData] = useState({
        title: "",
        comments: "",
        test: "",
    });
    const [testOptions, setTestOptions] = useState([]);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [selectedTestDetails, setSelectedTestDetails] = useState(null);
    const [fullscreenActive, setFullscreenActive] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const presentationRef = useRef(null);

    const handleStartTest = () => {
        console.log("handleStartTest function called");
        startVideoCapture();
        console.log(typeof startVideoCapture);

        setFullscreenActive(true);
    };

    const handleFinishTest = () => {
        setModalStage("results");
    };

    const handleConfirmAndResults = () => {
        sendData(emotionData, formData, (error, response) => {
            if (error) {
                console.error("Error sending data: ", error);
            } else {
                console.log("Data sent successfully: ", response);
                setModalStage("dataSent");
            }
        });
        console.log("capturestop5");

        stopVideoCapture();
        console.log(formData);
    };

    useEffect(() => {
        if (data) {
            const options = data.map((test) => ({
                value: test.id,
                label: test.title,
            }));
            setTestOptions(options);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            test: selectedOption,
        }));
        setSelectedTestId(selectedOption.value);
    };

    const fetchTestDetails = async (testId) => {
        toast
            .promise(Api.getData(`tests/${testId}/`), {
                error: "Something went wrong, please try later.",
            })
            .then((res) => {
                console.log(res.data);
                setSelectedTestDetails(res.data);
                setFirstStimulus(res.data.stimulus[0].title);
            })
            .catch((e) =>
                toast(({ closeToast }) => (
                    <span className="text-danger fw-bold">{e.message}</span>
                ))
            );
    };

    useEffect(() => {
        if (selectedTestId) {
            fetchTestDetails(selectedTestId);
        }
    }, [selectedTestId]);

    const startTest = () => {
        // Implement your logic to start the test
    };

    const restartTest = () => {
        // Implement your logic to restart the test
        setFormData({
            title: "",
            comments: "",
            test: null,
        });
        setModalStage("initial");
        console.log("capturestop4");

        stopVideoCapture();
    };

    const closeModalHandler = () => {
        // Implement your logic to close the modal
        closeShowModal();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (modalStage === "initial") {
            if (!formData.test) {
                console.log(formData.test);

                alert("Please fill in all required fields.");
            } else {
                setModalStage("inProgress");
                startVideoCaptureAndCheckCamera();
                startTest();
            }
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused
                ? "#fff"
                : state.isSelected
                ? "#fff"
                : "#006674",
            width: "100%",
        }),
        control: () => ({
            width: "100%",
            display: "flex",
            border: "1px solid #ccc",
            borderRadius: "15px",
            paddingLeft: "32px",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "400",
            height: "42px",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
    };

    const enterFullScreen = () => {
        setTimeout(() => {
            if (presentationRef.current) {
                try {
                    if (presentationRef.current.requestFullscreen) {
                        presentationRef.current.requestFullscreen();
                    } else if (presentationRef.current.mozRequestFullScreen) {
                        presentationRef.current.mozRequestFullScreen();
                    } else if (
                        presentationRef.current.webkitRequestFullscreen
                    ) {
                        presentationRef.current.webkitRequestFullscreen();
                    } else if (presentationRef.current.msRequestFullscreen) {
                        presentationRef.current.msRequestFullscreen();
                    }
                } catch (e) {
                    console.error("Failed to enter full-screen mode: ", e);
                }
            } else {
                console.log("The element is not ready for full-screen mode.");
            }
        }, 500); // Delay of 0.5 seconds
    };

    return (
        <div className="show">
            <div className="show__box">
                <div className="show__box_top">
                    <img
                        className="closeIcon"
                        src={close}
                        alt="Close"
                        onClick={closeShowModal}
                    />
                </div>

                {modalStage === "initial" && (
                    <form className="test-info" onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="profile container">
                            {/* ...other form elements... */}
                            {/* <div className="profile__form-inputs">
                                <label
                                    className="profile__form-label"
                                    htmlFor="title"
                                >
                                    Title *
                                </label>
                                <br></br>
                                <input
                                    className="test__form-input"
                                    type="text"
                                    id="title"
                                    name="title"
                                    required
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                            </div> */}
                            <div className="profile__form-inputs">
                                <label
                                    className="profile__form-label"
                                    htmlFor="comments"
                                >
                                    Comments
                                </label>
                                <br></br>
                                <input
                                    className="test__form-input"
                                    type="text"
                                    id="comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="profile__form-inputs">
                                <label className="profile__form-label">
                                    Test *
                                </label>
                                <Select
                                    maxMenuHeight={200}
                                    options={testOptions}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "rgba(0, 102, 116, 0.5)",
                                            primary: "#006674",
                                            primary50: "none",
                                        },
                                    })}
                                    onChange={handleSelectChange}
                                    required
                                    isSearchable={true}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn text-white mt-3 mx-auto"
                                // type="button"
                                style={{ backgroundColor: "#1BBDD4" }}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Start
                            </button>
                        </div>
                    </form>
                )}

                {modalStage === "inProgress" && !firstDetectionCaptured && (
                    <div>Loading Stimuli...</div>
                )}

                {modalStage === "inProgress" &&
                    firstDetectionCaptured &&
                    !isReady && (
                        <div>
                            <p>
                                Everything's ready. Please click the button
                                below to start the test on fullscreen mode
                            </p>
                            <button
                                className="btn text-white mt-3 mx-auto"
                                style={{ backgroundColor: "#1BBDD4" }}
                                onClick={() => {
                                    setIsReady(true);
                                    handleStartTest();
                                    enterFullScreen();
                                }}
                            >
                                Open fullscreen mode
                            </button>
                        </div>
                    )}
                {modalStage === "inProgress" &&
                    firstDetectionCaptured &&
                    isReady && (
                        // Show the stimuli presentation here
                        <div>
                            <StimuliPresentation
                                stimuli={selectedTestDetails.stimulus}
                                onFinish={handleFinishTest}
                                onStimulusChange={onStimulusChange}
                                currentStimulusTitle={currentStimulusTitle}
                                fullscreenActive={fullscreenActive}
                                firstDetectionCaptured={firstDetectionCaptured}
                                presentationRef={presentationRef}
                                enterFullScreen={enterFullScreen}
                                setFullscreenActive={setFullscreenActive}
                                stopVideoCapture={stopVideoCapture}
                            />
                            <button
                                className="btn text-white mt-3 mx-auto"
                                type="button"
                                style={{ backgroundColor: "#1BBDD4" }}
                                onClick={restartTest}
                            >
                                Start Over
                            </button>
                            {/* <button
                            onClick={handleFinishTest}
                            className="btn text-white mt-3 mx-auto"
                            type="button"
                            style={{ backgroundColor: "#1BBDD4" }}
                        >
                            Analyze Results
                        </button> */}
                        </div>
                    )}

                {modalStage === "results" && (
                    <div>
                        <p>Test Completed. You can now analyze the results.</p>
                        <button
                            onClick={handleConfirmAndResults}
                            className="btn text-white mt-3 mx-auto"
                            type="button"
                            style={{ backgroundColor: "#1BBDD4" }}
                        >
                            Confirm & Get Results
                        </button>
                    </div>
                )}

                {modalStage === "dataSent" && (
                    <div>
                        <p>
                            Data sent successfully. You can now close this
                            window and view the results in the Results page.
                        </p>
                        <button
                            onClick={closeShowModal}
                            className="btn text-white mt-3 mx-auto"
                            type="button"
                            style={{ backgroundColor: "#1BBDD4" }}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
            <div className="show-bg" onClick={closeShowModal}></div>
        </div>
    );
};

export default FacialEmotionAnalysisModal;
