import React from "react";

export const DownloadIcon = ({ fillColor = "#1BBDD4" }) => {
    return (
        <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.4646 0.138611C13.3545 0.0227052 13.2036 -0.046875 13.0473 -0.046875H3.68488C1.95728 -0.046875 0.53125 1.37348 0.53125 3.1009V20.8052C0.53125 22.5328 1.95728 23.9532 3.68488 23.9532H16.3921C18.1197 23.9532 19.5457 22.5328 19.5457 20.8052V6.74727C19.5457 6.59657 19.4761 6.45173 19.3776 6.3415L13.4646 0.138611ZM13.6327 1.99952L17.5921 6.15602H15.0182C14.253 6.15602 13.6327 5.54151 13.6327 4.77631V1.99952ZM16.3921 22.7937H3.68488C2.6009 22.7937 1.69068 21.8952 1.69068 20.8052V3.1009C1.69068 2.01691 2.59504 1.11255 3.68488 1.11255H12.4732V4.77631C12.4732 6.18495 13.6096 7.31544 15.0182 7.31544H18.3863V20.8052C18.3863 21.8952 17.4819 22.7937 16.3921 22.7937Z"
                fill={fillColor}
            />
            <path
                d="M14.8092 18.7939H5.26721C4.94843 18.7939 4.6875 19.0547 4.6875 19.3737C4.6875 19.6924 4.94843 19.9534 5.26721 19.9534H14.8151C15.1339 19.9534 15.3948 19.6924 15.3948 19.3737C15.3948 19.0547 15.1339 18.7939 14.8092 18.7939Z"
                fill={fillColor}
            />
            <path
                d="M9.61517 16.7124C9.7254 16.8283 9.8761 16.8979 10.0383 16.8979C10.2007 16.8979 10.3514 16.8283 10.4615 16.7124L13.8587 13.066C14.0789 12.834 14.0615 12.463 13.8297 12.2486C13.5977 12.0283 13.2268 12.0455 13.0123 12.2775L10.618 14.8456V8.51526C10.618 8.19629 10.3571 7.93555 10.0383 7.93555C9.71954 7.93555 9.45862 8.19629 9.45862 8.51526V14.8456L7.07019 12.2775C6.84991 12.0457 6.48461 12.0283 6.2528 12.2486C6.02099 12.4689 6.0036 12.8342 6.22387 13.066L9.61517 16.7124Z"
                fill={fillColor}
            />
        </svg>
    );
};
