import React from 'react';

export const HeadsetIcon = ({color}) => {
  return (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 17H26.1333V12.75C26.1333 9.81936 24.855 7.00875 22.5796 4.93647C20.3041 2.86419 17.218 1.7 14 1.7C10.782 1.7 7.69588 2.86419 5.42044 4.93647C3.145 7.00875 1.86667 9.81936 1.86667 12.75V17H0V12.75C5.53285e-08 9.36849 1.475 6.12548 4.1005 3.73439C6.72601 1.3433 10.287 0 14 0C17.713 0 21.274 1.3433 23.8995 3.73439C26.525 6.12548 28 9.36849 28 12.75V17Z" fill={color}/>
      <path d="M5.81467 25H4.66667C4.05383 25 3.447 24.8707 2.88081 24.6194C2.31462 24.3681 1.80018 23.9998 1.36683 23.5355C0.491665 22.5979 0 21.3261 0 20V18C0 16.6739 0.491665 15.4021 1.36683 14.4645C2.242 13.5268 3.42899 13 4.66667 13H5.81467C6.25536 13.0079 6.67552 13.201 6.98455 13.5377C7.29358 13.8744 7.46673 14.3278 7.46667 14.8V23.2C7.46673 23.6722 7.29358 24.1256 6.98455 24.4623C6.67552 24.799 6.25536 24.9921 5.81467 25ZM4.66667 15C3.92406 15 3.21187 15.3161 2.68677 15.8787C2.16167 16.4413 1.86667 17.2044 1.86667 18V20C1.86667 20.7956 2.16167 21.5587 2.68677 22.1213C3.21187 22.6839 3.92406 23 4.66667 23H5.6V15H4.66667ZM23.3333 25H22.2133C21.7678 25 21.3405 24.8104 21.0254 24.4728C20.7103 24.1352 20.5333 23.6774 20.5333 23.2V14.8C20.5333 14.3226 20.7103 13.8648 21.0254 13.5272C21.3405 13.1896 21.7678 13 22.2133 13H23.3333C24.571 13 25.758 13.5268 26.6332 14.4645C27.5083 15.4021 28 16.6739 28 18V20C28 21.3261 27.5083 22.5979 26.6332 23.5355C25.758 24.4732 24.571 25 23.3333 25ZM22.4 23H23.3333C24.0759 23 24.7881 22.6839 25.3132 22.1213C25.8383 21.5587 26.1333 20.7956 26.1333 20V18C26.1333 17.2044 25.8383 16.4413 25.3132 15.8787C24.7881 15.3161 24.0759 15 23.3333 15H22.4V23Z" fill={color}/>
      <path d="M20.89 26.9991H14C13.7348 26.9991 13.4804 26.9148 13.2929 26.7648C13.1054 26.6148 13 26.4114 13 26.1992C13 25.9871 13.1054 25.7837 13.2929 25.6337C13.4804 25.4837 13.7348 25.3994 14 25.3994H21C21.2652 25.3994 21.5196 25.3152 21.7071 25.1652C21.8946 25.0152 22 24.8117 22 24.5996V23H24V24.5996C24.0018 24.9919 23.8834 25.3786 23.655 25.7258C23.4266 26.073 23.0953 26.3702 22.69 26.5912C22.156 26.8707 21.528 27.013 20.89 26.9991Z" fill={color}/>
    </svg>
  );
};