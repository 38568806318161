import React from 'react';

export const HeadsetIcon = () => {
  return (
    <svg width="225" height="324" viewBox="0 0 325 424" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block mx-auto">
      <g filter="url(#filter0_d_415_4399)">
        <path d="M19.2027 280.571C22.154 327.314 54.6183 419.558 160.865 414.588C204.249 418.434 294.204 397.015 306.954 280.571V271.696C315.454 273.826 318.759 269.033 319.349 266.37C320.235 263.116 320.199 252.846 319.349 245.482C318.428 237.495 310.2 233.532 306.954 230.87C306.954 74 190.083 30.4376 190.083 26C190.083 22.4499 172.965 8.10022 164.406 1C150.24 6.68018 137.845 22.5965 133.418 30.2884C51.9621 61.5293 23.3346 170.634 19.2027 224.182C14.1855 229.211 4.96369 237.02 5.00011 248.145V264.12C5.2952 267.375 8.54166 272.996 19.2027 269.033V280.571Z" fill="#FBFBFB"/>
        <path d="M19.2027 224.182C37.6188 102.058 103.019 58.0977 133.418 48.0389M19.2027 224.182C23.3346 170.634 51.9621 61.5293 133.418 30.2884M19.2027 224.182C14.1855 229.211 4.96369 237.02 5.00011 248.145V264.12C5.2952 267.375 8.54166 272.996 19.2027 269.033M19.2027 224.182V269.033M306.954 230.87C301.051 182.943 262.5 77 190.083 48.0389M306.954 230.87C306.954 74 190.083 30.4376 190.083 26M306.954 230.87C310.2 233.532 318.428 237.495 319.349 245.482C320.199 252.846 320.235 263.116 319.349 266.37C318.759 269.033 315.454 273.826 306.954 271.696M306.954 230.87V271.696M190.083 48.0389C183.295 46.2639 168.656 42.7137 164.406 42.7137C159.094 42.7137 144.042 45.3763 133.418 48.0389M190.083 48.0389C190.083 48.0389 190.083 34.6067 190.083 26M133.418 48.0389V30.2884M133.418 30.2884C137.845 22.5965 150.24 6.68017 164.406 1C172.965 8.10021 190.083 22.4499 190.083 26M306.954 271.696V280.571C294.204 397.015 204.249 418.434 160.865 414.588C54.6183 419.558 22.154 327.314 19.2027 280.571V269.033" stroke="#CCCCCC" stroke-linecap="round"/>
      </g>
      <path d="M28 242C29.6667 245.333 34 252.9 38 256.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M24.5 242C24.6667 262.667 32.7 308.7 63.5 327.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M21.5011 242.5C18.1678 277.5 30.2011 355.3 105.001 386.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M33 209.5C34.6667 206.833 45.5 192.3 55.5 187.5M37.5 213.5C46.5 204.667 73.2 186.1 108 182.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M25.5 206.5C27.1667 190.167 34.6 154.3 51 141.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M29 208C38.3333 183.333 67.2 130.2 108 115" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <circle cx="118" cy="106" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="118" cy="185" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="62" cy="126" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="65" cy="189" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="25" cy="225" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="76" cy="335" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="119" cy="395" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="48" cy="268" r="19" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle cx="48" cy="268" r="10" fill="#999999"/>
      <path d="M299 242C297.333 245.333 293 252.9 289 256.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M302.5 242C302.333 262.667 294.3 308.7 263.5 327.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M305.499 242.5C308.832 277.5 296.799 355.3 221.999 386.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M294 209.5C292.333 206.833 281.5 192.3 271.5 187.5M289.5 213.5C280.5 204.667 253.8 186.1 219 182.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M301.5 206.5C299.833 190.167 292.4 154.3 276 141.5" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <path d="M298 208C288.667 183.333 259.8 130.2 219 115" stroke="#999999" stroke-width="2" stroke-linecap="round"/>
      <circle r="19" transform="matrix(-1 0 0 1 209 106)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 209 185)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 265 126)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 262 189)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 302 225)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 251 335)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 208 395)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="19" transform="matrix(-1 0 0 1 279 268)" fill="#F3F3F3" stroke="#CCCCCC" stroke-width="2"/>
      <circle r="10" transform="matrix(-1 0 0 1 279 268)" fill="#999999"/>
      <defs>
        <filter id="filter0_d_415_4399" x="0.5" y="0.425293" width="324" height="423.075" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_415_4399"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_415_4399" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
};
