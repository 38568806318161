import React, { createContext, useCallback, useEffect } from 'react'

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getCortexToken } from './ws-base';

const WebSocketContext = createContext(null)

export { WebSocketContext }

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {

  const socketUrl = 'wss://localhost:6868'

  const {
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket
  } = useWebSocket(socketUrl, { 
    onMessage: (e) => setToken(JSON.parse(e.data)) 
  });


  useEffect(()=>{
    
      const token = localStorage.getItem('cortexToken')
    
      if(!token) {
        sendMessage(getCortexToken())
      }
  }, [])


  const setToken = useCallback((data) => {

      if (data?.result?.cortexToken) {
        const { cortexToken } = data.result
        localStorage.setItem('cortexToken', cortexToken)
      } else {
        sendMessage(getCortexToken())
      }
  
  }, [])

  const onSendMessage = useCallback((msg) => sendMessage(msg), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const obj = {
    onSendMessage,
    lastMessage,
    connectionStatus,
    getWebSocket
  }

  return (
    <WebSocketContext.Provider value={obj}>
      {children}
    </WebSocketContext.Provider>
  )
}
