import React from 'react';

export const InstructionsIcon = ({color}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.995 11.7581 30.3077 7.69134 27.3082 4.69185C24.3087 1.69235 20.2419 0.00502833 16 0V0ZM16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91974 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05844 6.10051 6.1005C8.05845 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72881 18.7994 2.00606 21.3576 3.06569C23.9157 4.12531 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.996 19.7118 28.5198 23.2705 25.8951 25.8951C23.2705 28.5198 19.7118 29.996 16 30Z" fill={color}/>
      <path d="M16 6C14.4092 6.00159 12.884 6.63424 11.7591 7.75911C10.8718 8.64645 10.2907 9.7829 10.0841 11.0042C9.99197 11.5488 10.4481 12 11.0005 12C11.5525 12 11.9877 11.5457 12.1242 11.0108C12.3004 10.3206 12.6593 9.6838 13.1716 9.17157C13.9217 8.42143 14.9391 8 16 8C16.9608 7.99906 17.8898 8.34396 18.6172 8.97164C19.3446 9.59932 19.8217 10.4678 19.9614 11.4184C20.1011 12.369 19.894 13.338 19.378 14.1485C18.8619 14.9589 18.0714 15.5565 17.151 15.832C16.5302 16.0126 15.9849 16.3899 15.597 16.9071C15.2091 17.4243 14.9996 18.0535 15 18.7V21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21V18.7C17.0019 18.4841 17.0736 18.2745 17.2045 18.1027C17.3354 17.931 17.5183 17.8062 17.726 17.747C19.1058 17.3331 20.2906 16.4366 21.064 15.2213C21.8374 14.006 22.1477 12.553 21.9383 11.1278C21.7289 9.70256 21.0137 8.40025 19.9234 7.45877C18.8331 6.5173 17.4405 5.99951 16 6Z" fill={color}/>
      <path d="M15 25C15 25.5523 15.4477 26 16 26C16.5523 26 17 25.5523 17 25C17 24.4477 16.5523 24 16 24C15.4477 24 15 24.4477 15 25Z" fill={color}/>
    </svg>
  );
};