import React from 'react'
import HeadsetPoint from '../../assets/images/SVG/HeadsetPoint'
import HeadsetImageStatus from '../../assets/images/SVG/HeadsetStatusImage'
import './styles.scss'

const electrodes = ["AF3","F7","F3","FC5","T7","P7","O1","O2","P8","T8","FC6","F4","F8","AF4","OVERALL"]

const HeadsetStatus = ({data}) => {

    // const mocked = {
    //     dev:[
    //         4,2,
    //         [2,0,1,0,1,0,0,4,0,1,0,1,0,1,24],
    //         98
    //     ],
    //     sid:"d02af7d5-2bc0-46f4-8804-026a42ad7841",
    //     time:1559903194.6721
    // }

    const converted = data ? data[2].map((item, index)=> ({type: electrodes[index], value: item})) : null
   
    const strategy = (type, value) => {

       const colors = {
           0: 'red',
           1: 'yellow',
           2: 'yellow',
           3: 'green',
           4: 'green'
       } 

        if(type === 'OVERALL') {
            return <div className='OVERALL'>{value} %</div>
        }

        return <div className={`${type} ${colors[value]}`}></div>
    }
    if(!data) return <div className='container fade-out'><HeadsetImageStatus /></div>

    return <div className='container fade-in'>
        {converted.map(item => strategy(item.type, item.value))}
        <div className='headset-point-left'><HeadsetPoint /></div>
        <div className='headset-point-right'><HeadsetPoint /></div>
        <HeadsetImageStatus />
       
    </div>
}

export default HeadsetStatus