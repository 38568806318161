import React from 'react';

import './header.scss'
import { Link } from 'react-router-dom';

const Header = () => {

  return (
    <div className="header">
      <div className="">
        <Link to="/profile" className="header__nickname" style={{color: "#FFFFFF"}}>{JSON.parse(localStorage.getItem("auth"))?.email}</Link>
      </div>
    </div>
  );
};

export default Header;