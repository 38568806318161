import React, { useEffect, useState, useCallback, useRef } from "react";
import { HeadsetIcon } from "../../assets/headset";
import { HeadsetsLists } from "../../components/Modal/ModalComponents/HeadsetsLists";
import { CustomModal } from "../../components/Modal/CustomModal";
import { NoHeadset } from "../../components/Modal/ModalComponents/NoHeadsets";

import { useHistory } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import {
  queryHeadsets,
  connectHeadset,
  disconnectHeadset,
  createSession,
  subscribeDevice,
  getCortexToken
} from "../../api/ws-base";

import HeadsetStatus from "../../components/HeadsetStatus";

const Headset = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [headset, setHeadset] = useState([]);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null)
  const [connected, setConnected] = useState(null)
  const [isPaused, setIsPaused] = useState(false);
  const [devData, setDevData] = useState(null)


  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const history = useHistory();

  const ws = useRef(null);

  useEffect(() => {
    if (!isPaused) {
      ws.current = new WebSocket("wss://localhost:6868");
      gettingData();

      if (!connected) {
        ws.current.onopen = () => {
          ws.current.send(queryHeadsets())
        }
      }

    }

    return () => ws.current.close();
  }, [ws]);


  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = e => {
      if (isPaused) return;
      const parsed = JSON.parse(e.data);

      if (parsed) {
        const { result, warning, dev, error } = parsed;

        // console.log('on-message websocket', parsed)

        if (warning?.code === 104) {
          ws.current.send(queryHeadsets())
        }

        if (result && result[0]?.status === 'connected') {
          setConnected(result)
          !sessionId && ws.current.send(createSession(result[0]?.id))
        }

        if (result?.command === "disconnect") {
          setConnected(null)
          ws.current.send(queryHeadsets())
        }

        if (result?.status === 'activated') {
          setSessionId(result.id)
          ws.current.send(subscribeDevice(result.id))
        }

        if (error?.code === -32019) {
          ws.current.send(getCortexToken(20))
        }

        if (dev) {
          // console.log('dev stream', dev)
          setDevData(dev)
        }

        if (Array.isArray(result) && result.length) {
          setHeadset(result);
          localStorage.setItem("headset", JSON.stringify(result));
        } else {
          // setHeadset([])
        }

        if (error?.code === -32004) {
          setHeadset([])
        }

      }
    }
  }, []);


  const connect = (id) => {
    ws.current.send(connectHeadset(id));
  };

  const disconnect = (id) => {
    ws.current.send(disconnectHeadset(id));
    setModalOpen(false)
    setConnected(null)
    setHeadset([])
  };

  const modalHandler = () => {
    setModalOpen((prev) => !prev);
    !headset.length && ws.current.send(queryHeadsets())
    // if (modalOpen && connected && connected[0]?.id) {
    //   ws.current.send(createSession(connected[0]?.id))
    // }
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <ToastContainer />
        {connected ? (
          <div className="headset_status_wrap fade-in">
            <div onClick={modalHandler} className="available_button fade-in">
              Available Devices
            </div>
            <div className="d-flex headset_status_wrap_row">
              <HeadsetStatus data={devData} />
              <div className="connect_quality fade-in">
                <span className="connect_quality__title">
                  Ensuring good contact quality
                </span>
                <span className="connect_quality__description">
                  Make sure you are sitting up straight with <br /> your hair
                  tied together, adjust the device <br /> to full contact, you
                  should achieve a <br /> minimum of 88%.
                </span>
                <button
                  onClick={() => history.push('/tests-list')}
                  type="button"
                  className="btn text-white mx-auto connect_quality__button"
                  style={{ backgroundColor: "#1BBDD4" }}
                >
                  Go to test
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center mt-5 fade-in">
            <HeadsetIcon />
            <button
              type="button"
              onClick={modalHandler}
              className="btn text-white mt-3 mx-auto"
              style={{ backgroundColor: "#1BBDD4" }}
            >
              Connect device
            </button>
          </div>
        )}

        <CustomModal
          open={modalOpen}
          onClose={modalHandler}
          children={
            headset?.length ? (
              <HeadsetsLists
                battery={devData && devData[3]}
                changed={changed}
                setChanged={(e) => setChanged(e)}
                connect={connect}
                disconnect={disconnect}
                data={headset}
                loading={loading}
                getHeadset={() => ws.current.send(queryHeadsets())}
              />
            ) : (
              <NoHeadset getHeadset={() => ws.current.send(queryHeadsets())} />
            )
          }
        />
      </div>
    </>
  );
};

export default Headset;
