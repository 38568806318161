import React from "react";

const HeadsetImageStatus = () => {
  return (
    <svg
      width="234"
      height="305"
      viewBox="0 0 234 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1311_4414)">
        <path
          d="M14.2152 201.562C16.3379 235.095 39.6876 301.27 116.105 297.704C147.308 300.463 212.008 285.098 221.178 201.562V195.195C227.292 196.723 229.669 193.285 230.094 191.374C230.73 189.04 230.705 181.672 230.094 176.39C229.431 170.659 223.513 167.817 221.178 165.906C221.178 53.3696 137.119 22.1183 137.119 18.9348C137.119 16.388 124.808 6.09363 118.652 1C108.463 5.07489 99.5477 16.4931 96.3636 22.0112C37.7772 44.4232 17.187 122.694 14.2152 161.109C10.6066 164.717 3.97388 170.318 4.00008 178.3V189.76C4.21232 192.095 6.54731 196.127 14.2152 193.285V201.562Z"
          fill="#FFF7EA"
        />
        <path
          d="M14.2152 161.109C27.4609 73.4983 74.4998 41.9614 96.3636 34.7453M14.2152 161.109C17.187 122.694 37.7772 44.4232 96.3636 22.0112M14.2152 161.109C10.6066 164.717 3.97388 170.318 4.00008 178.3V189.76C4.21232 192.095 6.54731 196.127 14.2152 193.285M14.2152 161.109V193.285M221.178 165.906C216.933 131.524 189.205 55.5217 137.119 34.7453M221.178 165.906C221.178 53.3696 137.119 22.1183 137.119 18.9348M221.178 165.906C223.513 167.817 229.431 170.659 230.094 176.39C230.705 181.672 230.73 189.04 230.094 191.374C229.669 193.285 227.292 196.723 221.178 195.195M221.178 165.906V195.195M137.119 34.7453C132.237 33.4719 121.709 30.9251 118.652 30.9251C114.831 30.9251 104.005 32.8352 96.3636 34.7453M137.119 34.7453C137.119 34.7453 137.119 25.1092 137.119 18.9348M96.3636 34.7453V22.0112M96.3636 22.0112C99.5477 16.4931 108.463 5.0749 118.652 0.999997C124.808 6.09363 137.119 16.388 137.119 18.9348M221.178 195.195V201.562C212.008 285.098 147.308 300.463 116.105 297.704C39.6876 301.27 16.3379 235.095 14.2152 201.562V193.285"
          stroke="#999999"
          stroke-width="0.717391"
          stroke-linecap="round"
        />
      </g>
      <path
        d="M20.793 173.892C21.9886 176.283 25.0973 181.711 27.9669 184.294"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M18.2812 173.892C18.4008 188.718 24.1639 221.742 46.2595 235.229"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M16.1293 174.25C13.738 199.359 22.3706 255.172 76.0315 277.554"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M24.3809 150.577C25.5765 148.664 33.3483 138.237 40.5222 134.794M27.6091 153.446C34.0656 147.109 53.22 133.79 78.1852 131.207"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M18.6406 148.065C19.8597 134.545 25.2969 104.856 37.2928 94.2607"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M21.5098 149.501C28.2054 131.805 48.9141 93.6876 78.1837 82.7832"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M215.207 173.892C214.011 176.283 210.903 181.711 208.033 184.294"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M217.719 173.892C217.599 188.718 211.836 221.742 189.74 235.229"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M219.871 174.25C222.262 199.359 213.629 255.172 159.969 277.554"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M211.619 150.577C210.423 148.664 202.652 138.237 195.478 134.794M208.391 153.446C201.934 147.109 182.78 133.79 157.815 131.207"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M216.643 148.065C215.47 134.545 210.242 104.856 198.708 94.2607"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <path
        d="M214.49 149.501C207.795 131.805 187.086 93.6876 157.816 82.7832"
        stroke="#5A6A6E"
        stroke-width="1.43478"
        stroke-linecap="round"
      />
      <defs>
        <filter
          id="filter0_d_1311_4414"
          x="0.77106"
          y="0.587891"
          width="233.018"
          height="303.51"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.86957" />
          <feGaussianBlur stdDeviation="1.43478" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1311_4414"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1311_4414"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HeadsetImageStatus;
