import React, { useRef, useState, useEffect } from "react";
import * as faceapi from "face-api.js";
import axios from "axios";
import FacialEmotionAnalysisIcon from "../../assets/images/SVG/FacialEmotionAnalysisIcon";
import FacialEmotionAnalysisModal from "../../components/FacialEmotionAnalysisModal";
import { toast, ToastContainer } from "react-toastify";
import Api from "../../api";
import * as XLSX from "xlsx";
import { useCallback } from "react";

const CustomWebcam = () => {
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const intervalIdRef = useRef(null);
    const photoRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [emotions, setEmotions] = useState(null);
    const [showTestsModal, setShowTestsModal] = useState(false);
    const [showTestsData, setShowTestsData] = useState(null);
    const [emotionDataList, setEmotionDataList] = useState([]);
    const [currentStimulusTitle, setCurrentStimulusTitle] = useState("null");
    const currentStimulusTitleRef = useRef(currentStimulusTitle);
    const emotionDataListRef = useRef(emotionDataList);
    const [firstDetectionCaptured, setFirstDetectionCaptured] = useState(false);
    const [firstStimulus, setFirstStimulus] = useState("");

    useEffect(() => {
        emotionDataListRef.current = emotionDataList;
    }, [emotionDataList]);

    const stopVideoCapture = useCallback(() => {
        console.log("Video capture stopped.");
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
            console.log("Turned off camera");
            streamRef.current = null;
        }
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!showTestsModal) {
            console.log("capturestop7");

            stopVideoCapture();
            setEmotionDataList([]);
        }
        return () => {
            console.log("capturestop8");

            stopVideoCapture();
        };
    }, [showTestsModal, stopVideoCapture]);

    useEffect(() => {
        return () => {
            console.log("capturestop9");

            // Ensure camera is turned off when the component unmounts
            stopVideoCapture();
            setFirstDetectionCaptured(false);
        };
    }, []);

    useEffect(() => {
        currentStimulusTitleRef.current = currentStimulusTitle;
    }, [currentStimulusTitle]);

    useEffect(() => {
        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    const loadModels = async () => {
        await Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]);
    };

    const startVideoCaptureAndCheckCamera = async () => {
        console.log("Starting video capture and checking camera readiness.");
        try {
            await loadModels();
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {},
            });
            let video = videoRef.current;
            if (video) {
                video.srcObject = stream;
                video.play();
            }

            streamRef.current = stream;

            const checkForFace = async () => {
                try {
                    const faceDetected = await detectFaces();
                    if (faceDetected) {
                        console.log(
                            "Camera is ready and face detection was successful."
                        );
                        setFirstDetectionCaptured(true);
                    } else {
                        console.log("No faces detected, retrying...");
                        setTimeout(checkForFace, 1000); // Retry after 1 second
                    }
                } catch (err) {
                    console.error("Error during face detection: ", err);
                    // Handle the face detection error
                }
            };

            video.onloadeddata = async () => {
                if (showTestsModal) {
                    // Only start checking for a face if the modal is still open
                    checkForFace();
                } else {
                    console.log("capturestop10");

                    stopVideoCapture();
                }
            };
        } catch (err) {
            console.error("Error accessing the camera: ", err);
            alert("There was an error accessing the camera: " + err.message);
            // Additional error handling can be done here
        }
    };

    useEffect(() => {
        // Reset firstDetectionCaptured when showTestsModal is false
        if (!showTestsModal) {
            setFirstDetectionCaptured(false);
            console.log("capturestop11");

            stopVideoCapture();
        }
    }, [showTestsModal, stopVideoCapture]);

    const startVideoCapture = async () => {
        console.log("Video capture started.");
        try {
            await loadModels();
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {},
            });
            let video = videoRef.current;
            if (video) {
                video.srcObject = stream;
                video.play();
            }

            streamRef.current = stream;
            intervalIdRef.current = setInterval(() => {
                detectFaces();
            }, 500);
        } catch (err) {
            console.error("Error accessing the camera: ", err);
            // Alert the user
            alert("There was an error accessing the camera: " + err.message);
            // You might also want to handle this error in other ways,
            // such as updating the UI or disabling certain functionality
        }
    };

    const detectFaces = async () => {
        const video = videoRef.current;
        let faceDetected = false;

        if (video) {
            const detections = await faceapi
                .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks()
                .withFaceExpressions();
            console.log("Detections:", detections);
            if (detections.length > 0) {
                if (!firstDetectionCaptured) {
                    setFirstDetectionCaptured(true);
                }
                faceDetected = true;

                const detectedEmotions = detections[0].expressions;
                const emotionData = {
                    snapshot: emotionDataListRef.current.length + 1,
                    emotions: Object.keys(detectedEmotions).map((emotion) => ({
                        emotion,
                        probability: detectedEmotions[emotion],
                    })),
                    stimulus: currentStimulusTitleRef.current,
                };
                setEmotions(emotionData);

                // Call send data function after setting emotions
                setEmotionDataList((prevData) => [...prevData, emotionData]);
                console.log("Emotion Data:", emotionData);
            }
        }

        return faceDetected;
    };

    const sendData = (emotionData, formInputData, callback) => {
        console.log("Sending Emotion Data:", emotionData);
        console.log(formInputData);

        const userData = JSON.parse(localStorage.getItem("auth"));

        // Filter out rows with stimulus equal to null
        const filteredEmotionData = emotionData.filter(
            (data) => data.stimulus !== "null"
        );

        if (filteredEmotionData.length > 0) {
            // Process emotion data for the first worksheet
            const excelData = filteredEmotionData.map((data) => {
                const row = {
                    Snapshot: data.snapshot - 1,
                    Stimulus: data.stimulus,
                };
                data.emotions.forEach((emotionData) => {
                    row[emotionData.emotion] = emotionData.probability;
                });
                return row;
            });

            // Aggregate data for the second worksheet (averages)
            const emotionAggregates = {};
            filteredEmotionData.forEach((data) => {
                if (!emotionAggregates[data.stimulus]) {
                    emotionAggregates[data.stimulus] = { counts: 0, sums: {} };
                }

                data.emotions.forEach((emotion) => {
                    if (
                        !emotionAggregates[data.stimulus].sums[emotion.emotion]
                    ) {
                        emotionAggregates[data.stimulus].sums[
                            emotion.emotion
                        ] = 0;
                    }
                    emotionAggregates[data.stimulus].sums[emotion.emotion] +=
                        emotion.probability;
                });

                emotionAggregates[data.stimulus].counts++;
            });

            const averages = Object.keys(emotionAggregates).map((stimulus) => {
                const averages = {};
                Object.keys(emotionAggregates[stimulus].sums).forEach(
                    (emotion) => {
                        averages[emotion] =
                            emotionAggregates[stimulus].sums[emotion] /
                            emotionAggregates[stimulus].counts;
                    }
                );
                return { Stimulus: stimulus, ...averages };
            });

            // Create the workbook and add the worksheets
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            const averagesWorksheet = XLSX.utils.json_to_sheet(averages);

            XLSX.utils.book_append_sheet(workbook, worksheet, "Emotions");
            XLSX.utils.book_append_sheet(
                workbook,
                averagesWorksheet,
                "Emotion Averages"
            );

            // Write the workbook to a binary string
            const binaryString = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "binary",
            });
            const binaryBuffer = new ArrayBuffer(binaryString.length);
            const view = new Uint8Array(binaryBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                view[i] = binaryString.charCodeAt(i) & 0xff;
            }

            // Create form data for sending
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([binaryBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                "EmotionData.xlsx"
            );
            formData.append("description", formInputData.comments);
            formData.append("test", formInputData.test.value);
            formData.append("test_type", "emotion_analysis");
            formData.append("status", true);

            // Send the data to the backend
            axios
                .post("/api/results/create/", formData, {
                    headers: {
                        Authorization: `JWT ${userData?.access}`,
                    },
                })
                .then((response) => {
                    console.log(
                        "Emotions data sent successfully",
                        response.data
                    );
                    callback(null, response.data);
                })
                .catch((error) => {
                    console.error("Error sending emotions data", error);
                    callback(error, null);
                });
        }
    };

    const getTests = async () => {
        toast
            .promise(Api.getData("tests/"), {
                error: "Something went wrong, please try later.",
            })
            .then((res) => {
                console.log(res.data);
                setShowTestsModal(true);
                setShowTestsData(res.data);
            })
            .catch((e) =>
                toast(({ closeToast }) => (
                    <span className="text-danger fw-bold">{e.message}</span>
                ))
            );
    };

    const closeShowModal = () => {
        setShowTestsModal(false);
        setShowTestsData(null);
        setFirstDetectionCaptured(false);
        console.log("capturestop6");

        stopVideoCapture();
    };

    // Function to send iframes to the back
    const sendIframeToBack = () => {
        const iframes = document.querySelectorAll("iframe");
        iframes.forEach((iframe) => {
            iframe.style.zIndex = -1;
        });
    };

    // Add an event listener to catch iframes that pop up unexpectedly
    useEffect(() => {
        const iframeHandler = (event) => {
            sendIframeToBack();
        };

        window.addEventListener("DOMNodeInserted", iframeHandler);

        return () => {
            window.removeEventListener("DOMNodeInserted", iframeHandler);
        };
    }, []);

    return (
        <div className="text-center mt-5 fade-in">
            <FacialEmotionAnalysisIcon />
            <button
                type="button"
                onClick={async () => await getTests()}
                className="btn text-white mt-3 mx-auto"
                style={{ backgroundColor: "#1BBDD4" }}
            >
                Analyze
            </button>
            <video ref={videoRef} style={{ display: "none" }}></video>
            {showTestsModal ? (
                <FacialEmotionAnalysisModal
                    data={showTestsData}
                    closeShowModal={closeShowModal}
                    startVideoCapture={startVideoCapture}
                    stopVideoCapture={stopVideoCapture}
                    sendData={sendData}
                    emotionData={emotionDataList}
                    onStimulusChange={setCurrentStimulusTitle}
                    currentStimulusTitle={currentStimulusTitle}
                    firstDetectionCaptured={firstDetectionCaptured}
                    setFirstStimulus={setFirstStimulus}
                    startVideoCaptureAndCheckCamera={
                        startVideoCaptureAndCheckCamera
                    }
                />
            ) : null}
        </div>
    );
};

export default CustomWebcam;
