
export const RefreshIcon = ({fill, size}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_810_732" fill={fill}>
        <path d="M19.06 11.1331C19.5365 11.1927 19.8782 11.6285 19.7775 12.098C19.356 14.0626 18.3504 15.862 16.883 17.2542C15.2047 18.8467 13.0262 19.8079 10.7186 19.9741C8.41106 20.1404 6.11723 19.5014 4.22798 18.166C2.33873 16.8306 0.970951 14.8815 0.357703 12.6507C-0.255546 10.4199 -0.0763245 8.04546 0.86483 5.93199C1.80598 3.81853 3.45084 2.09677 5.51913 1.0601C7.58742 0.0234271 9.95118 -0.264022 12.2077 0.246731C14.1805 0.693279 15.9646 1.72581 17.3311 3.19887C17.6576 3.55088 17.5867 4.10009 17.2076 4.39485V4.39485C16.8286 4.68961 16.2855 4.61753 15.9525 4.27156C14.8378 3.11315 13.4041 2.30032 11.8238 1.94263C9.95967 1.52068 8.00692 1.75815 6.29826 2.61457C4.58961 3.47098 3.23076 4.89336 2.45325 6.63934C1.67574 8.38532 1.52769 10.3469 2.0343 12.1898C2.54092 14.0327 3.67087 15.6429 5.23161 16.7461C6.79236 17.8493 8.68734 18.3772 10.5937 18.2398C12.5 18.1025 14.2997 17.3084 15.6862 15.9929C16.8616 14.8776 17.6783 13.4462 18.0443 11.8807C18.1536 11.4131 18.5836 11.0736 19.06 11.1331V11.1331Z" fill={fill}/>
      </mask>
      <path d="M19.06 11.1331C19.5365 11.1927 19.8782 11.6285 19.7775 12.098C19.356 14.0626 18.3504 15.862 16.883 17.2542C15.2047 18.8467 13.0262 19.8079 10.7186 19.9741C8.41106 20.1404 6.11723 19.5014 4.22798 18.166C2.33873 16.8306 0.970951 14.8815 0.357703 12.6507C-0.255546 10.4199 -0.0763245 8.04546 0.86483 5.93199C1.80598 3.81853 3.45084 2.09677 5.51913 1.0601C7.58742 0.0234271 9.95118 -0.264022 12.2077 0.246731C14.1805 0.693279 15.9646 1.72581 17.3311 3.19887C17.6576 3.55088 17.5867 4.10009 17.2076 4.39485V4.39485C16.8286 4.68961 16.2855 4.61753 15.9525 4.27156C14.8378 3.11315 13.4041 2.30032 11.8238 1.94263C9.95967 1.52068 8.00692 1.75815 6.29826 2.61457C4.58961 3.47098 3.23076 4.89336 2.45325 6.63934C1.67574 8.38532 1.52769 10.3469 2.0343 12.1898C2.54092 14.0327 3.67087 15.6429 5.23161 16.7461C6.79236 17.8493 8.68734 18.3772 10.5937 18.2398C12.5 18.1025 14.2997 17.3084 15.6862 15.9929C16.8616 14.8776 17.6783 13.4462 18.0443 11.8807C18.1536 11.4131 18.5836 11.0736 19.06 11.1331V11.1331Z" fill={fill} stroke={fill} stroke-width="2" mask="url(#path-1-inside-1_810_732)"/>
      <path d="M13.5 4.5H17.5V1" stroke={fill} stroke-linecap="round"/>
    </svg>

  );
};