import React from 'react';
import {Grid, TextField} from "@mui/material";

export const FormInput = ({name, label, onChange, value, required = true, type, error, helperText, multiline, rows, ...props}) => {
  return (
    <Grid container item justifyContent="center" alignItems="center" className="my-3 mx-auto">
      <TextField
        name={name}
        label={label}
        onChange={onChange}
        value={value}
        required={required}
        multiline={multiline}
        fullWidth
        rows={rows}
        variant="outlined"
        type={type}
        error={error}
        helperText={helperText}
        {...props}
      />
    </Grid>
  );
};