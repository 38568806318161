import React, { useEffect, useState } from "react";
import ShowResultsModal from "../../components/ShowResultsModal";
import { Grid } from "@mui/material";
import "./results.scss";
import Api from "../../api";
import { toast, ToastContainer } from "react-toastify";
import { ResultFileIcon } from "../../assets/images/SVG/ResultFileIcon";
import axios from "axios";
import { TableEmptyData } from "../../components/TableEmptyData/TableEmptyData";

const Results = () => {
    // const userData = JSON.parse(localStorage.getItem("neuroToken"));
    // console.log(userData);
    const userData = JSON.parse(localStorage.getItem("auth"));
    const [showResults, setShowResultsModal] = useState(false);
    const [showResultsData, setShowResultsData] = useState(null);

    const [results, setResults] = useState([]);

    // New Code
    // useEffect(() => {
    //     const fetchResults = async () => {
    //         try {
    //             const resultsResponse = await Api.getData(
    //                 "results/",
    //                 userData?.access
    //             );
    //             const emotionsResponse = await Api.getData(
    //                 "emotions/",
    //                 userData?.access
    //             );

    //             // Combine results and emotions data
    //             const combinedResults = [
    //                 ...resultsResponse.data,
    //                 ...emotionsResponse.data,
    //             ];
    //             setResults(combinedResults);
    //         } catch (error) {
    //             toast(({ closeToast }) => (
    //                 <span className="text-danger fw-bold">{error.message}</span>
    //             ));
    //         }
    //     };

    //     fetchResults();
    // }, [userData?.access]);
    // End of new code

    useEffect(() => {
        toast
            .promise(Api.getData("results/", userData?.access), {
                error: "Something went wrong, please try later.",
            })
            .then((res) => setResults(res.data))
            .catch((e) =>
                toast(({ closeToast }) => (
                    <span className="text-danger fw-bold">{e.message}</span>
                ))
            );
    }, [userData?.access]);

    // const download = async (id) => {
    //   const headers = {
    //     "Content-Type": "blob",
    //     Authorization: userData?.access ? `JWT ${userData?.access}` : "",
    //   };
    //   const config = {
    //     method: "GET",
    //     url: `https://neuroproject.synoserver.com/api/results/export/${id}/`,
    //     responseType: "arraybuffer",
    //     headers,
    //   };

    //   try {
    //     const response = await axios(config);

    //     const outputFilename = `${Date.now()}.xlsx`;
    //     // If you want to download file automatically using link attribute.
    //     const url = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", outputFilename);
    //     document.body.appendChild(link);
    //     link.click();

    //   } catch (error) {
    //     toast.warn("Something went wrong ... ")
    //     throw Error(error);
    //   }
    // };
    console.log(userData?.access);

    // const getResultById = async (id) => {
    //     try {
    //         const response = await Api.getData(
    //             `results/${id}/`,
    //             userData?.access
    //         );
    //         console.log("Response Status:", response.status);
    //         console.log("Response Headers:", response.headers);
    //         console.log("Response Body:", response.data);
    //         setShowResultsModal(true);
    //         setShowResultsData(response.data);
    //     } catch (e) {
    //         console.error("Error fetching result data:", e);
    //         toast.error(`Error: ${e.message}`);
    //     }
    // };

    const getFile = async (frontendId) => {
        const resultObject = results[frontendId];
        console.log(resultObject);

        if (frontendId >= 0 && frontendId < results.length) {
            if (resultObject) {
                setShowResultsData(resultObject);

                if (resultObject.test_type !== "emotion_analysis") {
                    await getResultById(resultObject.id);
                }
                setShowResultsModal(true);
            } else {
                console.error("File not available for the given index");
                setShowResultsData(null);
                setShowResultsModal(false);
            }
        } else {
            console.error("Index out of bounds");
            setShowResultsData(null);
            setShowResultsModal(false);
        }
    };

    const getResultById = async (id) => {
        toast
            .promise(Api.getData(`results/${id}/`, userData?.access), {
                error: `Something went wrong fetching ${
                    "result/" + id
                }, please try later.`,
            })
            .then((res) => {
                console.log(res.data);
                setShowResultsModal(true);
                setShowResultsData(res.data);
            })
            .catch((e) =>
                toast(({ closeToast }) => (
                    <span className="text-danger fw-bold">{e.message}</span>
                ))
            );
    };

    const closeShowModal = () => {
        setShowResultsModal(false);
        setShowResultsData(null);
    };

    console.log(results);

    return (
        <div className="test-result-wrap">
            <ToastContainer />
            <span className="result-title mb-3">Test results</span>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                className="fw-bold p-2 rounded-top mt-4 text-center"
                style={{
                    backgroundColor: "#F3F3F3",
                    color: "#006674",
                    border: "1px solid #CCCCCC",
                }}
            >
                <Grid item xs={1}>
                    {" "}
                    #{" "}
                </Grid>
                <Grid item xs={4} className="text-start">
                    {" "}
                    Test
                </Grid>
                <Grid item xs={2}>
                    {" "}
                    Date{" "}
                </Grid>
                <Grid item xs={1}>
                    {" "}
                    Data{" "}
                </Grid>
                <Grid item xs={4} className="text-start">
                    {" "}
                    Comments{" "}
                </Grid>
            </Grid>
            {results.length ? (
                results.map((obj, index) => (
                    <Grid
                        key={index}
                        item
                        container
                        alignItems="center"
                        className="p-2 text-center"
                        style={{
                            backgroundColor: "",
                            border: "1px solid #CCCCCC",
                            cursor: "pointer",
                            color: "#443E3E",
                        }}
                    >
                        <Grid item xs={1}>
                            {" "}
                            {index + 1}{" "}
                        </Grid>
                        <Grid item xs={4} className="text-start">
                            {" "}
                            {obj.title}{" "}
                        </Grid>
                        <Grid item xs={2}>
                            {" "}
                            {obj?.date}{" "}
                        </Grid>
                        <Grid item xs={1}>
                            <div
                                onClick={async () => {
                                    await getFile(index);
                                }}
                            >
                                <ResultFileIcon />
                            </div>
                        </Grid>
                        <Grid item xs={4} className="text-start">
                            {" "}
                            {obj.test_type === "video"
                                ? ""
                                : obj.description}{" "}
                        </Grid>
                    </Grid>
                ))
            ) : (
                <TableEmptyData />
            )}
            {showResults ? (
                <ShowResultsModal
                    data={showResultsData}
                    closeShowModal={closeShowModal}
                />
            ) : null}
        </div>
    );
};

export default Results;
