import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {RefreshIcon} from "../../../assets/images/SVG/RefreshIcon";

export const NoHeadset = ({ getHeadset }, props) => {
  return (
    <Grid
      container
      direction="column"
      style={{width: 250}}
      className='fade-in'
    >
      <Typography className="d-block mr-auto">
        Available devices
      </Typography>
      <Typography className="d-block mx-auto my-4">
        No devices found
      </Typography>
      <Button
        variant="contained"
        color="error"
        endIcon={<RefreshIcon fill={"#FFF"} size={16}/>}
        style={{backgroundColor: "#e91e63"}}
        onClick={getHeadset}
      >
        <strong>
          Refresh
        </strong>
      </Button>
    </Grid>
  );
};