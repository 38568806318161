import close from "./../../assets/images/close.svg";
import "./showResults.scss";
import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { DownloadIcon } from "../../assets/images/SVG/DownloadIcon";

const ShowResultsModal = ({ closeShowModal, data }) => {
    const [emotionAverages, setEmotionAverages] = useState({});
    console.log(data?.test_type);
    console.log(data);
    const isEmotionAnalysis = data?.test_type === "emotion_analysis" || "video";

    const foundResult = (type) => {
        return data?.test_results_parameters?.find(
            (item) => item.parameter.title === type
        );
    };

    useEffect(() => {
        if (isEmotionAnalysis && data?.file) {
            const fetchExcelData = async () => {
                try {
                    const response = await axios.get(data.file, {
                        responseType: "arraybuffer",
                    });
                    const workbook = XLSX.read(response.data, {
                        type: "buffer",
                    });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);
                    calculateEmotionMeans(jsonData);
                } catch (error) {
                    console.error("Error fetching Excel data", error);
                }
            };
            fetchExcelData();
        }
    }, [data, isEmotionAnalysis]);

    const calculateEmotionMeans = (jsonData) => {
        const sum = jsonData.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                if (key !== "Snapshot" && key !== "Stimulus" && key !== "time") {
                    acc[key] = (acc[key] || 0) + parseFloat(row[key] || 0);
                }
            });
            return acc;
        }, {});

        const averages = Object.keys(sum).reduce((acc, key) => {
            acc[key] = sum[key] / jsonData.length;
            return acc;
        }, {});

        setEmotionAverages(averages);
    };

    if (isEmotionAnalysis) {
        const emotionColors = {
            neutral: "#febf10",
            happy: "#8fc8da",
            sad: "#8e79b7",
            angry: "#f39f6d",
            fearful: "#c981b7",
            disgusted: "#23b46b",
            surprised: "#7fcbb4",
        };

        const downloadFile = async () => {
            // Assuming data contains the file URL directly
            if (data && data.file) {
                try {
                    const response = await axios.get(data.file, {
                        responseType: "blob",
                    });
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "file.xlsx"); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error("Error downloading file: ", error);
                }
            }
        };

        return (
            <div className="show">
                <div className="show__box">
                    <div className="show__box_top">
                        <p className="show__title">{data?.test?.title || ""}</p>
                        <img
                            width={16}
                            height={16}
                            src={close}
                            alt="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => closeShowModal()}
                        />
                    </div>
                    <span className="show__text">
                        {data?.description || ""}
                    </span>

                    <div>
                        <button
                            className="download-btn"
                            onClick={() => downloadFile(data?.file)}
                        >
                            <DownloadIcon fillColor="#FFFFFF" />
                            <span className="download-text">Download</span>
                        </button>
                    </div>

                    {Object.entries(emotionAverages).map(
                        ([emotion, average]) => (
                            <div className="w-100" key={emotion}>
                                <div className="d-flex flex-row align-center">
                                    <span className="emotion-title">
                                        {emotion}
                                    </span>
                                    <div className="emotion-container-centered">
                                        <div
                                            style={{
                                                width: `${average * 100}%`,
                                                backgroundColor:
                                                    emotionColors[
                                                        emotion.toLowerCase()
                                                    ] || "#000",
                                            }}
                                            className="emotion-container-left"
                                        ></div>
                                    </div>
                                    <div className="emotion-values">
                                        {`${(average.toFixed(4) * 100).toFixed(
                                            2
                                        )}%`}
                                    </div>
                                </div>
                                {/* <div className="start-point">0</div> */}
                            </div>
                        )
                    )}
                </div>
                <div className="show-bg" onClick={() => closeShowModal()}></div>
            </div>
        );
    } else {
        return (
            <div className="show">
                <div className="show__box">
                    <div className="show__box_top">
                        <p className="show__title">{data?.title || ""}</p>
                        <img
                            width={16}
                            height={16}
                            src={close}
                            alt="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => closeShowModal()}
                        />
                    </div>
                    <span className="show__text">
                        {data?.description || ""}
                    </span>

                    <div className="w-100">
                        <div className="stimuli-type-title">FA1</div>
                        <div>
                            <div className="d-flex flex-row justify-content-between info">
                                <div>Less Affected</div>{" "}
                                <div>Highly affected</div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-primary">
                                    Test Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA1")?.test_value /
                                                    2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA1")?.test_value > 0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-primary`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA1"
                                            )?.test_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-secondary">
                                    Rest Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA1")?.rest_value /
                                                    2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA1")?.rest_value > 0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-secondary`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA1"
                                            )?.rest_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-red">
                                    Result
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA1")
                                                    ?.result_value / 2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA1")?.result_value >=
                                            0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-red`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA1"
                                            )?.result_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="center-point">0</div>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="stimuli-type-title">FA2</div>
                        <div>
                            <div className="d-flex flex-row justify-content-between info">
                                <div>Less Affected</div>{" "}
                                <div>Highly affected</div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-primary">
                                    Test Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA2")?.test_value /
                                                    2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA2")?.test_value >= 0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-primary`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA2"
                                            )?.test_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-secondary">
                                    Rest Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA2")?.rest_value /
                                                    2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA2")?.rest_value >= 0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-secondary`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA2"
                                            )?.rest_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-red">
                                    Result
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${Math.abs(
                                                foundResult("FA2")
                                                    ?.result_value / 2
                                            )}%`,
                                        }}
                                        className={`row-container-centered__${
                                            foundResult("FA2")?.result_value >=
                                            0
                                                ? "positive-value"
                                                : "negative-value"
                                        } color-red`}
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "FA2"
                                            )?.result_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="center-point">0</div>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="stimuli-type-title">COH</div>
                        <div>
                            <div className="d-flex flex-row justify-content-between info">
                                <div>Effective Interpersonal Communication</div>{" "}
                                <div>Goal Oriented</div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-primary">
                                    Test Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${foundResult(
                                                "COH"
                                            )?.test_value.toFixed(0)}%`,
                                        }}
                                        className="row-container-left color-primary"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "COH"
                                            )?.test_value.toFixed(3)}
                                            %
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-secondary">
                                    Rest Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${foundResult(
                                                "COH"
                                            )?.test_value.toFixed(0)}%`,
                                        }}
                                        className="row-container-left color-secondary"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "COH"
                                            )?.rest_value.toFixed(3)}
                                            %
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-red">
                                    Result
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${
                                                foundResult("COH")?.result_value
                                            }%`,
                                        }}
                                        className="row-container-left color-red"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "COH"
                                            )?.result_value.toFixed(3)}
                                            %
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="start-point">0</div>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="stimuli-type-title">TAR</div>
                        <div>
                            <div className="d-flex flex-row justify-content-between info">
                                <div>Lower Cognitive Effort</div>{" "}
                                <div>Higher Cognitive Effort</div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-primary">
                                    Test Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${
                                                foundResult("TAR")?.test_value
                                            }%`,
                                        }}
                                        className="row-container-left color-primary"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "TAR"
                                            )?.test_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-secondary">
                                    Rest Value
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${
                                                foundResult("TAR")?.rest_value
                                            }%`,
                                        }}
                                        className="row-container-left color-secondary"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "TAR"
                                            )?.rest_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-center">
                                <span className="result-description d-red">
                                    Result
                                </span>
                                <div className="row-container-centered">
                                    <div
                                        style={{
                                            width: `${
                                                foundResult("TAR")?.result_value
                                            }%`,
                                        }}
                                        className="row-container-left color-red"
                                    >
                                        <div className="values_positive">
                                            {foundResult(
                                                "TAR"
                                            )?.result_value.toFixed(3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="start-point">0</div>
                        </div>
                    </div>
                </div>

                <div className="show-bg" onClick={() => closeShowModal()}></div>
            </div>
        );
    }
};

export default ShowResultsModal;
