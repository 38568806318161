// import React from "react";
import * as React from "react";

export const EmotionAnalysis = ({ color }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 500 500"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                d="M354.7 210.2c3.5 12.2 5.3 24.8 5.3 37.5V464h-96v16h104c4.4 0 8-3.6 8-8V247.8c0-14.2-2-28.3-5.9-42l-15.4 4.4zM176 264c0-17.7-14.3-32-32-32s-32 14.3-32 32 14.3 32 32 32 32-14.3 32-32zm-48 0c0-8.8 7.2-16 16-16s16 7.2 16 16-7.2 16-16 16-16-7.2-16-16z"
            />
            <path
                fill={color}
                d="M285.7 445.7l24-24c12 5.7 26.3.6 32-11.3s.6-26.3-11.3-32c-12-5.7-26.3-.6-32 11.3-3.1 6.5-3.1 14.2 0 20.7L276.7 432H248v-16h24v-16h-24v-24h12.7l13.7 13.7 11.3-11.3-16-16c-1.5-1.5-3.5-2.3-5.7-2.3h-16v-16h49.4c4.4 12.5 18.1 19 30.6 14.6s19-18.1 14.6-30.6-18.1-19-30.6-14.6c-6.8 2.4-12.2 7.8-14.6 14.6H248v-16h32c2.1 0 4.2-.8 5.7-2.3l24-24c12 5.7 26.3.7 32-11.3s.7-26.3-11.3-32c-12-5.7-26.3-.7-32 11.3-3.1 6.5-3.1 14.2 0 20.7L276.7 296H248v-16h24v-16h-24v-16h24v-16h-24v-24h-16v256h-88.4c-10.7 0-20.9-4.2-28.5-11.8-7.5-7.4-11.6-17.6-11.5-28.1l.2-40.1H144v-16h-40.1l.1-32c0-4.4-3.5-8-8-8H58.6L92 210.5l.1-.2-15.4-4.5V206.1L40.3 333.8c-1.2 4.2 1.3 8.7 5.5 9.9.7.2 1.4.3 2.2.3h40l-.4 80c-.1 30.8 24.9 55.9 55.7 56H240c4.4 0 8-3.6 8-8v-24h32c2.1 0 4.2-.8 5.7-2.3zM320 392c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0-64c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0-72c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8z"
            />
            <path fill={color} d="M200 432H216V448H200z" />
            <path fill={color} d="M200 368H216V384H200z" />
            <path fill={color} d="M200 304H216V320H200z" />
            <path fill={color} d="M200 240H216V256H200z" />
            <path fill={color} d="M320 432H344V448H320z" />
            <path
                fill={color}
                d="M384 0H96C43 0 0 43 0 96s43 96 96 96h192v32c0 4.4 3.6 8 8 8 1.7 0 3.3-.5 4.7-1.5l53.9-38.5H384c53 0 96-43 96-96S437 0 384 0zm0 176h-32c-1.7 0-3.3.5-4.6 1.5l-43.4 31V184c0-4.4-3.6-8-8-8H96c-44.2 0-80-35.8-80-80s35.8-80 80-80h288c44.2 0 80 35.8 80 80s-35.8 80-80 80z"
            />
            <path
                fill={color}
                d="M240 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM384 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM96 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"
            />
            <circle fill={color} cx={76} cy={84} r={12} />
            <circle fill={color} cx={116} cy={84} r={12} />
            <path
                fill={color}
                d="M67.6 113l8.8 13.3c11.9-7.9 27.3-7.9 39.2 0l8.8-13.3a51.07 51.07 0 00-56.8 0z"
            />
            <circle fill={color} cx={220} cy={84} r={12} />
            <circle fill={color} cx={260} cy={84} r={12} />
            <path fill={color} d="M216 112H264V128H216z" />
            <circle fill={color} cx={364} cy={84} r={12} />
            <circle fill={color} cx={404} cy={84} r={12} />
            <path
                fill={color}
                d="M364.4 105.7l-8.8 13.3c17.2 11.4 39.6 11.4 56.8 0l-8.8-13.3c-11.9 7.9-27.3 7.9-39.2 0z"
            />
        </svg>
    );
};
export default EmotionAnalysis;

// import * as React from "react";
// // import svg, { path, circle } from "react-native-svg";
// /* SVGR has dropped some elements not supported by react-native-svg: style */

// function svgComponent(props) {
//     return (
//         <svg
//             id="Layer_1"
//             xmlns="http://www.w3.org/2000/svg"
//             x="0px"
//             y="0px"
//             viewBox="0 0 32 32"
//             xmlSpace="preserve"
//             fill={props.color}
//             style={{ enableBackground: "new 0 0 480 480" }}
//             {...props}
//         >
// <path
//     fill={props.color}
//     d="M354.7 210.2c3.5 12.2 5.3 24.8 5.3 37.5V464h-96v16h104c4.4 0 8-3.6 8-8V247.8c0-14.2-2-28.3-5.9-42l-15.4 4.4zM176 264c0-17.7-14.3-32-32-32s-32 14.3-32 32 14.3 32 32 32 32-14.3 32-32zm-48 0c0-8.8 7.2-16 16-16s16 7.2 16 16-7.2 16-16 16-16-7.2-16-16z"
// />
// <path
//     fill={props.color}
//     d="M285.7 445.7l24-24c12 5.7 26.3.6 32-11.3s.6-26.3-11.3-32c-12-5.7-26.3-.6-32 11.3-3.1 6.5-3.1 14.2 0 20.7L276.7 432H248v-16h24v-16h-24v-24h12.7l13.7 13.7 11.3-11.3-16-16c-1.5-1.5-3.5-2.3-5.7-2.3h-16v-16h49.4c4.4 12.5 18.1 19 30.6 14.6s19-18.1 14.6-30.6-18.1-19-30.6-14.6c-6.8 2.4-12.2 7.8-14.6 14.6H248v-16h32c2.1 0 4.2-.8 5.7-2.3l24-24c12 5.7 26.3.7 32-11.3s.7-26.3-11.3-32c-12-5.7-26.3-.7-32 11.3-3.1 6.5-3.1 14.2 0 20.7L276.7 296H248v-16h24v-16h-24v-16h24v-16h-24v-24h-16v256h-88.4c-10.7 0-20.9-4.2-28.5-11.8-7.5-7.4-11.6-17.6-11.5-28.1l.2-40.1H144v-16h-40.1l.1-32c0-4.4-3.5-8-8-8H58.6L92 210.5l.1-.2-15.4-4.5V206.1L40.3 333.8c-1.2 4.2 1.3 8.7 5.5 9.9.7.2 1.4.3 2.2.3h40l-.4 80c-.1 30.8 24.9 55.9 55.7 56H240c4.4 0 8-3.6 8-8v-24h32c2.1 0 4.2-.8 5.7-2.3zM320 392c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0-64c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0-72c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8z"
// />
// <path fill={props.color} d="M200 432H216V448H200z" />
// <path fill={props.color} d="M200 368H216V384H200z" />
// <path fill={props.color} d="M200 304H216V320H200z" />
// <path fill={props.color} d="M200 240H216V256H200z" />
// <path fill={props.color} d="M320 432H344V448H320z" />
// <path
//     fill={props.color}
//     d="M384 0H96C43 0 0 43 0 96s43 96 96 96h192v32c0 4.4 3.6 8 8 8 1.7 0 3.3-.5 4.7-1.5l53.9-38.5H384c53 0 96-43 96-96S437 0 384 0zm0 176h-32c-1.7 0-3.3.5-4.6 1.5l-43.4 31V184c0-4.4-3.6-8-8-8H96c-44.2 0-80-35.8-80-80s35.8-80 80-80h288c44.2 0 80 35.8 80 80s-35.8 80-80 80z"
// />
// <path
//     fill={props.color}
//     d="M240 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM384 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM96 32c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 112c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"
// />
// <circle fill={props.color} cx={76} cy={84} r={12} />
// <circle fill={props.color} cx={116} cy={84} r={12} />
// <path
//     fill={props.color}
//     d="M67.6 113l8.8 13.3c11.9-7.9 27.3-7.9 39.2 0l8.8-13.3a51.07 51.07 0 00-56.8 0z"
// />
// <circle fill={props.color} cx={220} cy={84} r={12} />
// <circle fill={props.color} cx={260} cy={84} r={12} />
// <path fill={props.color} d="M216 112H264V128H216z" />
// <circle fill={props.color} cx={364} cy={84} r={12} />
// <circle fill={props.color} cx={404} cy={84} r={12} />
// <path
//     fill={props.color}
//     d="M364.4 105.7l-8.8 13.3c17.2 11.4 39.6 11.4 56.8 0l-8.8-13.3c-11.9 7.9-27.3 7.9-39.2 0z"
// />
//         </svg>
//     );
// }

// export default svgComponent;
