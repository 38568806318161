import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom'
import WebSocketProvider from './api/websocket';
import './index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>

    <Router>
      <WebSocketProvider>
        <App className='wrap' />
      </WebSocketProvider>
    </Router>

  </React.StrictMode>,
  document.getElementById('root')
);
