import React, { useState, useEffect } from "react";
import "./testPassing.scss";
import { NoFile } from "../../components/TableEmptyData/NoFile";
import { NoAudio } from "../../assets/images/SVG/NoAudio";
import { NoVideo } from "../../assets/images/SVG/NoVideo";
import { Audio } from "../../assets/images/SVG/Audio";

import { CountDown } from "../../components/CountDown";

const TestPassing = ({
  launcherAccess,
  title,
  description,
  index,
  length,
  duration,
  setTest,
  seconds,
  id,
  category,
  file,
  ...props
}) => {
  const [dur, setDur] = useState();

  useEffect(() => {
    setDur(seconds);
    let prev = seconds;
    const interval = setInterval(() => {
      if (prev <= 0) {
        setTest(index);
        if (index === length) clearInterval(interval);
        return;
      }
      --prev;
      setDur(seconds);
    }, 1000);

    return () => clearInterval(interval);
  }, [id, index, seconds, setTest, length]);

  const drawTest = () => {
    if (category?.title?.toLowerCase() === "image") {
      return (
        <img
          className="fade-in"
          src={
            file
              ? file
              : "https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
          }
          alt="testImage"
          style={{
            height: "inherit",
            borderRadius: "20px",
            position: "absolute",
          }}
        />
      );
    }
    if (category?.title?.toLowerCase() === "video") {
      if (file) {
        return (
          <video
            className="fade-in"
            style={{ borderRadius: 20 }}
            width="720"
            height="auto"
            autoPlay
          >
            <source src={file} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      } else {
        return <NoFile children={<NoVideo />} />;
      }
    }
    if (category?.title?.toLowerCase() === "audio") {
      if (file) {
        return (
          <div className="audio-slide fade-in">
            <Audio />
            <audio
              autoPlay
              controlsList="nodownload"
              controls
              style={{ opacity: 0, visibility: "hidden", position: "absolute" }}
            >
              <source src={file} type="audio/mpeg" />
            </audio>
          </div>
        );
      } else {
        return <NoFile children={<NoAudio />} />;
      }
    }
  };

  return (
    <div className="passing">
      <h1 className="fullTest--title">{title}</h1>

      <p className="fullTest--description py-1">{description}</p>
      <br />
      <div className="passing__level">
        <span>
          {index} of {length}
        </span>

        <CountDown seconds={seconds} />
      </div>
      <br />
      <div
        className={`fade-in test-content background-filter ${0 && "not-found"}`}
      >
        <div className="slide u-non-blurred">{drawTest()}</div>
      </div>
    </div>
  );
};

export default TestPassing;
