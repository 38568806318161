import React, { useEffect, useState } from 'react';
import Api from '../../api/index.js';
import './profile.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FormInput} from "../../components/FormInput/FormInput";
import {MenuItem} from "@mui/material";
import { useHistory } from 'react-router';

const Profile = ({ user_id, countries, genders, cities, setAuth }) => {
  const [profile, setProfile] = useState({
    id: "",
    email: "",
    user_profile: {
      first_name: null,
      last_name: null,
      gender: null,
      age: null,
      phone: null,
      country: null,
      city: null
    }
  });

 const history = useHistory()

  useEffect(() => {
    Api.getData("auth/users/" + user_id)
      .then((res) => {
        setProfile(res.data);
      })
      .catch(e => {
        setAuth(null)
        history.push('/login')
        console.error(e);
        toast(({closeToast}) =>
          <span className="text-danger fw-bold">
          {e.message}
        </span>);
      })
  }, [user_id]);

  const handleChange = ({target}) => {
    const {name, value} = target;
    if (name === "email") {
      setProfile(prev => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setProfile( prev => ({
        ...prev,
        user_profile: {
          ...prev.user_profile,
          [name]: value,
        }
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.promise(
      Api.putData("auth/users/update/" + user_id + "/", profile),
      {
        pending: 'Sending...',
        success: 'Successfully updated',
        error: 'Something went wrong, please try later.'
      }
    ).catch(e => console.error(e));
  };

  return (
    <div className="profile container-profile">
      <ToastContainer />
      <form className="profile__form" onSubmit={handleSubmit}>
        <div className="profile__form-inner">
          <div className="profile__form-left">
            <div className="profile__form-inputs">

              <FormInput
                label="Name"
                name="first_name"
                value={profile.user_profile?.first_name ? profile.user_profile?.first_name : ""}
                type="text"
                className="profile__form-input"
                onChange={handleChange}
              />

            </div>
            <div className="profile__form-inputs">

              <FormInput
                label="Last name"
                name="last_name"
                value={profile.user_profile?.last_name ? profile.user_profile?.last_name : ""}
                type="text"
                className="profile__form-input"
                onChange={handleChange}
              />

            </div>
            <div className="profile__form-inputs">
              <FormInput
                label="Gender"
                name="gender"
                value={profile.user_profile?.gender ? profile.user_profile?.gender : ""}
                select
                className="profile__form-input"
                onChange={handleChange}
              >
                {genders?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </FormInput>

            </div>
            <div className="profile__form-inputs">
              <FormInput
                label="Age"
                name="age"
                type="number"
                value={profile.user_profile?.age ? profile.user_profile?.age : ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="profile__form-right">
            <div className="profile__form-inputs">
              <FormInput
                label="Email"
                name="email"
                type="email"
                value={profile?.email ? profile?.email : ""}
                onChange={handleChange}
              />

            </div>
            <div className="profile__form-inputs">

              <FormInput
                label="Phone"
                name="phone"
                className="profile__form-input"
                type="number"
                pattern="[0-9]+"
                value={profile.user_profile?.phone ? profile.user_profile?.phone : ""}
                onChange={handleChange}
              />

            </div>
            <div className="profile__form-inputs">

              <FormInput
                label="Country"
                name="country"
                value={profile.user_profile?.country ? profile.user_profile?.country : ""}
                onChange={handleChange}
                select
              >
                {countries?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </FormInput>

            </div>
            <div className="profile__form-inputs">

              <FormInput
                label="City"
                name="city"
                select
                value={profile.user_profile?.city ? profile.user_profile?.city : ""}
                onChange={handleChange}
              >
                {cities?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </FormInput>

            </div>
          </div>
        </div>
        <button
          type='submit'
          className="profile__form-btn"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default Profile;