export const ResultFileIcon = ({ fill, size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1388_4485)">
        <path
          d="M15.4646 0.138611C15.3545 0.0227052 15.2036 -0.046875 15.0473 -0.046875H5.68488C3.95728 -0.046875 2.53125 1.37348 2.53125 3.1009V20.8052C2.53125 22.5328 3.95728 23.9532 5.68488 23.9532H18.3921C20.1197 23.9532 21.5457 22.5328 21.5457 20.8052V6.74727C21.5457 6.59657 21.4761 6.45173 21.3776 6.3415L15.4646 0.138611ZM15.6327 1.99952L19.5921 6.15602H17.0182C16.253 6.15602 15.6327 5.54151 15.6327 4.77631V1.99952ZM18.3921 22.7937H5.68488C4.6009 22.7937 3.69068 21.8952 3.69068 20.8052V3.1009C3.69068 2.01691 4.59504 1.11255 5.68488 1.11255H14.4732V4.77631C14.4732 6.18495 15.6096 7.31544 17.0182 7.31544H20.3863V20.8052C20.3863 21.8952 19.4819 22.7937 18.3921 22.7937Z"
          fill="#006674"
        />
        <path
          d="M11.5101 3.00194C11.4281 3.0078 11.3489 3.03371 11.2794 3.07748C11.2099 3.12125 11.1522 3.18156 11.1115 3.25291L8.45411 7.68187L6.88921 5.99148C6.84613 5.93809 6.79252 5.8941 6.73173 5.86231C6.67093 5.83052 6.60424 5.81151 6.5358 5.8066C6.46737 5.80169 6.39866 5.81103 6.33394 5.83382C6.26923 5.85661 6.2099 5.89242 6.15964 5.93912C6.10938 5.98583 6.06927 6.04234 6.04181 6.10521C6.01434 6.16808 6.00011 6.23602 6 6.30463C5.99989 6.37323 6.01389 6.44108 6.04115 6.50404C6.06841 6.56701 6.10833 6.62372 6.15843 6.67059L8.17361 8.84816C8.22624 8.90547 8.29152 8.9497 8.36426 8.97734C8.43699 9.00498 8.51517 9.01522 8.59258 9.00733C8.66999 8.99944 8.74449 8.97364 8.81015 8.9319C8.87582 8.89015 8.93083 8.8336 8.97082 8.76685L11.9751 3.76951C12.0255 3.6908 12.0528 3.59964 12.054 3.50619C12.0552 3.41275 12.0301 3.32085 11.9817 3.24092C11.9333 3.16099 11.8634 3.0962 11.7801 3.05395C11.6967 3.01171 11.6032 2.99373 11.5101 3.00194Z"
          fill="#006674"
        />
        <path
          d="M11.5101 9.00299C11.4281 9.00886 11.3489 9.03489 11.2794 9.07865C11.2099 9.12242 11.1522 9.18261 11.1115 9.25397L8.45411 13.6829L6.88921 11.9925C6.84613 11.9391 6.79252 11.8952 6.73173 11.8634C6.67093 11.8316 6.60424 11.8127 6.5358 11.8078C6.46737 11.8029 6.39866 11.8121 6.33394 11.8349C6.26923 11.8577 6.2099 11.8935 6.15964 11.9402C6.10938 11.9869 6.06927 12.0435 6.04181 12.1064C6.01434 12.1693 6.00011 12.2371 6 12.3057C5.99989 12.3743 6.01389 12.4421 6.04115 12.5051C6.06841 12.5681 6.10833 12.6248 6.15843 12.6716L8.17361 14.8492C8.22624 14.9065 8.29152 14.9508 8.36426 14.9784C8.43699 15.006 8.51517 15.0163 8.59258 15.0084C8.66999 15.0005 8.74449 14.9747 8.81015 14.933C8.87582 14.8912 8.93083 14.8347 8.97082 14.7679L11.9751 9.76318C12.0234 9.68459 12.049 9.59419 12.0491 9.50194C12.0492 9.40969 12.0238 9.31917 11.9757 9.24047C11.9275 9.16178 11.8586 9.09798 11.7764 9.05605C11.6942 9.01412 11.6021 8.99579 11.5101 9.00299ZM11.5101 15.0042C11.4281 15.0101 11.3489 15.0361 11.2794 15.0799C11.2099 15.1237 11.1522 15.1839 11.1115 15.2552L8.45411 19.6842L6.88921 17.9938C6.84613 17.9404 6.79252 17.8964 6.73173 17.8646C6.67093 17.8328 6.60424 17.8139 6.5358 17.809C6.46737 17.8041 6.39866 17.8133 6.33394 17.8361C6.26923 17.8589 6.2099 17.8947 6.15964 17.9414C6.10938 17.9881 6.06927 18.0447 6.04181 18.1076C6.01434 18.1705 6.00011 18.2383 6 18.3069C5.99989 18.3755 6.01389 18.4434 6.04115 18.5063C6.06841 18.5693 6.10833 18.626 6.15843 18.6729L8.17361 20.8505C8.22624 20.9078 8.29152 20.952 8.36426 20.9796C8.43699 21.0073 8.51517 21.0175 8.59258 21.0096C8.66999 21.0017 8.74449 20.9759 8.81015 20.9342C8.87582 20.8924 8.93083 20.8359 8.97082 20.7691L11.9751 15.7644C12.0234 15.6858 12.049 15.5954 12.0491 15.5032C12.0492 15.4109 12.0238 15.3204 11.9757 15.2417C11.9275 15.163 11.8586 15.0992 11.7764 15.0573C11.6942 15.0154 11.6021 14.997 11.5101 15.0042Z"
          fill="#006674"
        />
      </g>
      <defs>
        <clipPath id="clip0_1388_4485">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
