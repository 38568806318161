import React from 'react';

export const Account = ({color}) => {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="6" r="5.25" stroke={color} strokeWidth="1.5"/>
      <path d="M27 24.2143C27 18.5731 21.1797 14 14 14C6.8203 14 1 18.5731 1 24.2143" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

  );
};