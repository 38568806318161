import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Api from "../../api";
import { TableEmptyData } from "../../components/TableEmptyData/TableEmptyData";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";
import { queryHeadsets } from "../../api/ws-base";
import './styles.scss'

const TestsList = ({ launcherAccess }) => {
  const [tests, setTests] = useState([]);
  const history = useHistory();
  const [device, setDevice] = useState(null);

  const { sendMessage } = useWebSocket(
    "wss://localhost:6868",
    {
      onMessage: (e) => initDevices(e.data),
    }
  );


  const initDevices = (data) => {
    const parsed = JSON.parse(data);
    const { result } = parsed;

    if (result) {
      const find_connecting = result.find(
        (item) => item.status === "connected"
      );
      if (find_connecting) {
        setDevice(find_connecting);
      }
    }
  };

  useEffect(() => {
    sendMessage(queryHeadsets());
    toast
      .promise(Api.getData("tests/"), {
        error: "Could not fetch data!",
      })
      .then((res) => setTests(res.data))
      .catch((e) => console.error(e));
  }, []);

  const notFound = () => {
    toast.info('Device is not connected')
    history.push(`/headset`)
  }


  const goto_test = (id) => {
    device ? history.push(`/tests/${id}`, { device }) : notFound();
  };

  return (
    <div className="test-list-wrap">
      <span className="test-title">Tests</span>
      <Grid container alignItems="center" className="text-dark mt-3">
        <Grid
          item
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          className="my-3"
        >
      
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          className="mt-3"
        >
          <Grid container>
            <Grid
              item
              container
              // alignItems="center"
              // justifyContent="center"
              className="fw-bold p-2 rounded-top text-center"
              style={{
                backgroundColor: "#F3F3F3",
                color: "#006674",
                border: "1px solid #CCCCCC",
              }}
            >
              <Grid item xs={1}>
                #
              </Grid>
              <Grid item xs={4} className="text-start">
                Title
              </Grid>
              <Grid item xs={6} className="text-start">
                Description
              </Grid>
            </Grid>
          </Grid>
          {tests.length ? (
            tests.map((test, index) => (
              <Grid
                key={index}
                item
                container
                className="p-2 text-center"
                style={{
                  backgroundColor: "",
                  border: "1px solid #CCCCCC",
                  cursor: "pointer",
                }}
                onClick={() => goto_test(test.id)}
              >
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={4} className="text-start">
                  {test.title}
                </Grid>
                <Grid item xs={6} className="text-start">
                  {test.description}
                </Grid>
              </Grid>
            ))
          ) : (
            <TableEmptyData />
          )}
        </Grid>
      </Grid>
    </div>

  );
};

export default TestsList;
