import React from 'react';
import {useHistory} from "react-router-dom";
import {Box, Button, Grid, Modal} from "@mui/material";
import {Error404} from "../../assets/images/SVG/Error404";
import bgImage from "../../assets/images/profile-bg.svg";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const Page404 = () => {
  const history = useHistory();

  return (
    <Modal
      open={true}
      container={() => document.getElementById("parentContainer")}
    >
      <Box sx={style}>
        <Grid
          className="rounded p-5"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        >
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="p-5"
          >
            <Grid>
              <Error404/>
            </Grid>
            <Grid className="text-dark py-2">
              Sorry! Page is not found
            </Grid>

            <Button sx={{bgcolor: "#1BBDD4"}} variant="contained" className="my-2" onClick={() => history.goBack()}>
              Go back
            </Button>
          </Grid>

        </Grid>
      </Box>
    </Modal>
  );
};

export default Page404;