import React, { useState, useEffect, useReducer, useCallback } from "react";
import useWebSocket from "react-use-websocket";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Profile from "./pages/Profile";
import Api from "./api/index.js";
import { rootReducer } from "./store/reducers.js";
import { initialDataAC } from "./store/actions.js";
import Test from "./pages/Test";
import Results from "./pages/Results";
import Headset from "./pages/Headset/Headset";
import Page404 from "./pages/Page404/Page404";
import Instructions from "./pages/Instructions/Instructions";
import TestsList from "./pages/TestsList/TestsList";
import { getAccess } from "../src/api/ws-base";
import { toast } from "react-toastify";
import EmotionAnalysis from "./pages/EmotionAnalysis/EmotionAnalysis";
import VideoBasedEmotionAnalysis from "./pages/VideoBasedEmotionAnalysis/VideoBasedEmotionAnalysis";

const auth = JSON.parse(localStorage.getItem("auth"));

const initialState = {
    cities: [],
    countries: [],
    genders: [],
};

function App() {
    const [isAuth, setIsAuth] = useState(auth || null);
    const [state, dispatch] = useReducer(rootReducer, initialState);
    const [requested, setRequest] = useState(false);

    const { sendMessage } = useWebSocket("wss://localhost:6868", {
        onMessage: (e) => getAppAccess(JSON.parse(e.data)),
        onOpen: () => sendMessage(getAccess()),
        share: false,
    });

    useEffect(() => {
        localStorage.setItem("auth", JSON.stringify(isAuth));

        if (isAuth) {
            Promise.all([
                Api.getData("cities/"),
                Api.getData("countries/"),
                Api.getData("genders/"),
            ])
                .then((res) => {
                    const data = {};
                    res.forEach(
                        (item) =>
                            (data[item.config.url.replace("/", "")] = item.data)
                    );
                    dispatch(initialDataAC(data));
                })
                .catch((e) => console.error(e));
        }
    }, [isAuth, requested]);

    const getAppAccess = useCallback(
        (data) => {
            const { result, warning } = data;
            if (warning?.code === (104 || 103)) {
                toast.warn(warning.message, { autoClose: 3000 });
            }
            if (result && result?.accessGranted) {
                setRequest(result?.accessGranted);
            }
        },
        [requested]
    );

    return (
        <>
            {isAuth ? (
                <Layout>
                    <>
                        <Switch>
                            <Route path="/" exact>
                                <Redirect to="/profile" />
                            </Route>
                            <Route path="/profile" exact>
                                <Profile
                                    {...state}
                                    {...isAuth}
                                    setAuth={(e) => setIsAuth(e)}
                                />
                            </Route>
                            <Route path="/tests/:id" exact>
                                <Test
                                    {...state}
                                    {...isAuth}
                                    launcherAccess={requested}
                                />
                            </Route>
                            <Route
                                path="/emotion-analysis"
                                exact
                                component={EmotionAnalysis}
                            />
                            <Route
                                path="/video-based-emotion-analysis"
                                exact
                                component={VideoBasedEmotionAnalysis}
                            />

                            <Route path="/results" exact>
                                <Results {...state} {...isAuth} />
                            </Route>
                            <Route path="/headset" component={Headset} />
                            <Route
                                path="/instructions"
                                exact
                                component={Instructions}
                            />
                            <Route path="/tests-list" exact>
                                <TestsList launcherAccess={requested} />
                            </Route>
                            <Route path="*" component={Page404} />
                        </Switch>
                    </>
                </Layout>
            ) : (
                <Switch>
                    <Route
                        path="/registration"
                        exact
                        component={Registration}
                    />
                    <Route
                        path="/login"
                        exact
                        component={() => <Login setAuth={setIsAuth} />}
                    />
                    <Route
                        path="*"
                        component={() => <Redirect to="/login" />}
                    />
                </Switch>
            )}
        </>
    );
}

export default App;
