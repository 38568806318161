import React from 'react';

export const NoVideo = () => {
  return (
    <svg width="166" height="166" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1094_5579)">
        <circle cx="83" cy="77" r="61.5" stroke="white" strokeWidth="3" shapeRendering="crispEdges"/>
      </g>
      <g clipPath="url(#clip0_1094_5579)">
        <path fillRule="evenodd" clipRule="evenodd" d="M133.083 76.9536C133.083 104.6 110.44 127 82.4946 127C54.6426 127 32 104.6 32 76.9536C32 49.4 54.6426 27 82.4946 27C110.44 27 133.083 49.4 133.083 76.9536Z" fill="#CCCCCC"/>
        <path d="M108.824 73.0833C108.824 71.2047 108.078 69.403 106.751 68.0747C105.424 66.7463 103.624 66 101.748 66C99.8711 66 98.0713 66.7463 96.7443 68.0747C95.4174 69.403 94.6719 71.2047 94.6719 73.0833C94.6719 73.4591 94.821 73.8194 95.0864 74.0851C95.3518 74.3507 95.7117 74.5 96.087 74.5C96.4624 74.5 96.8223 74.3507 97.0877 74.0851C97.3531 73.8194 97.5022 73.4591 97.5022 73.0833C97.5022 72.2428 97.7512 71.4211 98.2177 70.7222C98.6842 70.0233 99.3472 69.4785 100.123 69.1568C100.899 68.8352 101.752 68.751 102.576 68.915C103.399 69.079 104.156 69.4838 104.75 70.0781C105.343 70.6725 105.748 71.4298 105.912 72.2542C106.075 73.0786 105.991 73.9332 105.67 74.7097C105.349 75.4863 104.805 76.1501 104.106 76.6171C103.408 77.0841 102.587 77.3333 101.748 77.3333C101.372 77.3333 101.012 77.4826 100.747 77.7483C100.482 78.0139 100.333 78.3743 100.333 78.75V81.5833C100.333 81.9591 100.482 82.3194 100.747 82.5851C101.012 82.8507 101.372 83 101.748 83C102.123 83 102.483 82.8507 102.748 82.5851C103.014 82.3194 103.163 81.9591 103.163 81.5833V80.025C104.761 79.6984 106.198 78.8291 107.229 77.5641C108.261 76.2991 108.824 74.7163 108.824 73.0833Z" fill="white"/>
        <path d="M101.747 88C102.584 88 103.263 87.3284 103.263 86.5C103.263 85.6716 102.584 85 101.747 85C100.909 85 100.23 85.6716 100.23 86.5C100.23 87.3284 100.909 88 101.747 88Z" fill="white"/>
        <path d="M61.6148 97C60.8324 97 60.05 96.7968 59.333 96.3873C58.6209 95.9878 58.0288 95.4043 57.6185 94.6975C57.2082 93.9907 56.9946 93.1866 57.0001 92.369V61.6313C57.0001 59.9468 57.8719 58.4447 59.333 57.6129C60.7925 56.7795 62.5266 56.7971 63.9717 57.6593L89.7469 73.0289C91.1569 73.8704 92 75.3549 92 77.0009C92 78.647 91.1569 80.1315 89.7469 80.9729L63.9733 96.3425C63.2607 96.7708 62.4458 96.998 61.6148 97ZM61.6212 60.2044C61.3721 60.2066 61.1276 60.2721 60.9106 60.3947C60.6907 60.5168 60.5078 60.6961 60.3813 60.9138C60.2548 61.1315 60.1894 61.3794 60.1921 61.6313V92.3706C60.1921 93.1176 60.6935 93.4839 60.9106 93.6071C61.128 93.7341 61.3756 93.7999 61.6272 93.7973C61.8788 93.7948 62.125 93.7241 62.3397 93.5927L88.1134 78.2231C88.7394 77.8503 88.808 77.2441 88.808 77.0009C88.808 76.7578 88.741 76.1515 88.1134 75.7788L62.3382 60.4091C62.1227 60.2758 61.8745 60.2049 61.6212 60.2044Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_1094_5579" x="0" y="0" width="166" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="10"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1094_5579"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1094_5579" result="shape"/>
        </filter>
        <clipPath id="clip0_1094_5579">
          <rect width="101.083" height="100" fill="white" transform="translate(32 27)"/>
        </clipPath>
      </defs>
    </svg>

  );
};