import React from 'react';

export const NoAudio = () => {
  return (
    <svg width="166" height="166" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1081_4712)">
        <circle cx="83" cy="77" r="61.5" stroke="white" strokeWidth="3" shapeRendering="crispEdges"/>
      </g>
      <g clipPath="url(#clip0_1081_4712)">
        <path fillRule="evenodd" clipRule="evenodd" d="M133.083 76.9536C133.083 104.6 110.44 127 82.4946 127C54.6426 127 32 104.6 32 76.9536C32 49.4 54.6426 27 82.4946 27C110.44 27 133.083 49.4 133.083 76.9536Z" fill="#CCCCCC"/>
        <path d="M84.3942 101.993H82.2786C80.8346 101.993 79.4736 101.436 78.4483 100.425L69.7335 91.8036C69.3935 91.4702 68.9343 91.2829 68.4555 91.2822H61.4572C58.4716 91.2822 57.3184 90.1377 57.3184 87.1841V66.8016C57.3184 63.8481 58.4716 62.7646 61.4572 62.7646L68.4592 62.7107C68.9321 62.7107 69.3978 62.5179 69.7371 62.1893L78.4519 53.5679C78.9538 53.0689 79.5508 52.6734 80.2083 52.4043C80.8658 52.1352 81.5707 51.9978 82.2823 52H84.3978C87.3833 52 89.8129 54.4036 89.8129 57.3572V96.6429C89.8082 98.0622 89.2354 99.4219 88.2199 100.424C87.2044 101.427 85.8289 101.991 84.3942 101.993ZM62.7335 66.2786C62.2548 66.2786 61.4572 66.2715 60.9285 66.2715C60.9285 66.7307 60.9285 67.5907 60.9285 68.0643V85.9215C60.9285 86.3951 60.9285 87.2646 60.9285 87.7072C61.3143 87.7072 62.2548 87.7072 62.7335 87.7072H68.4592C69.9032 87.7072 71.2642 88.2643 72.2895 89.275L81.0043 97.8965C81.3443 98.2298 81.8035 98.4172 82.2823 98.4179H84.3978C84.8765 98.4179 85.3356 98.2297 85.6741 97.8949C86.0127 97.56 86.2028 97.1058 86.2028 96.6322V57.3465C86.2028 56.8729 86.0127 56.4187 85.6741 56.0838C85.3356 55.7489 84.8765 55.5607 84.3978 55.5607H82.2823C81.8041 55.5645 81.3461 55.7514 81.0043 56.0822L72.2895 64.7036C71.7876 65.2026 71.1906 65.5981 70.5331 65.8672C69.8756 66.1363 69.1707 66.2737 68.4592 66.2715H62.7335V66.2786Z" fill="white"/>
        <path d="M108.824 73.0833C108.824 71.2047 108.078 69.403 106.751 68.0747C105.424 66.7463 103.624 66 101.748 66C99.8711 66 98.0713 66.7463 96.7443 68.0747C95.4174 69.403 94.6719 71.2047 94.6719 73.0833C94.6719 73.4591 94.821 73.8194 95.0864 74.0851C95.3518 74.3507 95.7117 74.5 96.087 74.5C96.4624 74.5 96.8223 74.3507 97.0877 74.0851C97.3531 73.8194 97.5022 73.4591 97.5022 73.0833C97.5022 72.2428 97.7512 71.4211 98.2177 70.7222C98.6842 70.0233 99.3472 69.4785 100.123 69.1568C100.899 68.8352 101.752 68.751 102.576 68.915C103.399 69.079 104.156 69.4838 104.75 70.0781C105.343 70.6725 105.748 71.4298 105.912 72.2542C106.075 73.0786 105.991 73.9332 105.67 74.7097C105.349 75.4863 104.805 76.1501 104.106 76.6171C103.408 77.0841 102.587 77.3333 101.748 77.3333C101.372 77.3333 101.012 77.4826 100.747 77.7483C100.482 78.0139 100.333 78.3743 100.333 78.75V81.5833C100.333 81.9591 100.482 82.3194 100.747 82.5851C101.012 82.8507 101.372 83 101.748 83C102.123 83 102.483 82.8507 102.748 82.5851C103.014 82.3194 103.163 81.9591 103.163 81.5833V80.025C104.761 79.6984 106.198 78.8291 107.229 77.5641C108.261 76.2991 108.824 74.7163 108.824 73.0833Z" fill="white"/>
        <path d="M101.747 88C102.584 88 103.263 87.3284 103.263 86.5C103.263 85.6716 102.584 85 101.747 85C100.909 85 100.23 85.6716 100.23 86.5C100.23 87.3284 100.909 88 101.747 88Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_1081_4712" x="0" y="0" width="166" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="10"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1081_4712"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1081_4712" result="shape"/>
        </filter>
        <clipPath id="clip0_1081_4712">
          <rect width="101.083" height="100" fill="white" transform="translate(32 27)"/>
        </clipPath>
      </defs>
    </svg>
  );
};