import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import Api from '../../api/index.js';
import './test.scss'
import TestPassing from "../TestPassing";
import { toast, ToastContainer } from "react-toastify";
import { CustomModal } from "../../components/Modal/CustomModal";
import { PassedTest } from "../../components/Modal/ModalComponents/PassedTest";
import { json2csv, json2csvAsync } from 'json-2-csv';

// import { pow_indexes } from '../../constants.js';


import {
  JsonToCsv,
  useJsonToCsv
} from 'react-json-csv';

import { closeSession, createSession, getCortexToken, subscribe } from '../../api/ws-base.js';

const Test = ({launcherAccess}) => {
  const [isTest, setIsTest] = useState(false);
  const [test, setTest] = useState(null);
  const [pending, setPending] = useState(true);
  const [activeTest, setActiveTest] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [finish, setFinish] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const [isPaused, setIsPaused] = useState(false);

  const message_arr = []
  
  const { id } = useParams();

  const history = useHistory();

  const ws = useRef(null);
  const testRef = useRef(null)
  const indexRef = useRef(null)

  const { saveAsCsv } = useJsonToCsv();

  indexRef.current = activeIndex

  const device = history.location?.state?.device

  useEffect(() => {
    Api.getData("tests/" + id)
      .finally(() => setPending(false))
      .then((res) => {
        setTest(res.data);
        testRef.current = res.data
      })
  }, [id]);


  useEffect(() => {
    if (!isPaused) {
      ws.current = new WebSocket("wss://localhost:6868");


      gettingData();
    }

    return () => ws.current.close();
  }, [ws, isPaused]);


  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = e => {
      if (isPaused) return;
      const parsed = JSON.parse(e.data);

      if (parsed !== null) {
        const { result, error, pow, warning } = parsed

        if (error?.code === (-32004 || 103)) {
          toast.info('Device is not connected')
          history.push('/headset')
        }

        if (error?.code === -32019) {
          ws.current.send(getCortexToken(10))
        }

        if (warning?.code === 0) {

          // const filtered = message_arr.map((element) => {
          //   return { ...element, pow: element.pow.filter((_, i) => pow_indexes.includes(i)) }
          // })
        
          const obj = {
            "test_results_stimulus": message_arr,
            "test": testRef.current.id
          }

          Api.postData('results/create/', obj).then(r => r)

        }

        if (result?.status === 'activated') {
          setSessionId(result.id)
          ws.current.send(subscribe(result.id))
        }

        if (pow) {
          message_arr.push({ ...parsed, 
            stimuli: testRef.current.stimulus[indexRef.current - 1].id,
            test_id: testRef?.current.id 
          })
        }
      }
    };
  }, [isPaused]);



  const starTest = () => {

    if (device?.status !== 'connected') return history.push('/headset')

    ws.current.send(createSession(device?.id))

    setActiveTest(test.stimulus[0]);
    setActiveIndex(1);
    setIsTest(true);

  };

  const setNewTest = () => {
    if (activeIndex === test.stimulus.length) {
      ws.current.send(closeSession(sessionId))

      setIsTest(false);
      setFinish(true);
    } else {
      setActiveTest(test.stimulus[activeIndex]);
      setActiveIndex(activeIndex + 1);
    }
  }

  if (pending) return <div>...loading</div>
  return (
    <div className="test">
      <ToastContainer />
      {!finish && <div className="py-5 my-5">
        <h2 className="test--heading">{test?.title}</h2>
        <p className="test--description">{test?.description}</p>
        <button
          onClick={()=> {launcherAccess ? starTest() : toast.warn('Confirm access in the Emotiv launcher and reload page', { autoClose: 15000 })}}
          className="profile__form-btn"
        >
          Start test
        </button>
      </div>}
      <CustomModal
        open={finish}
        onClose={() => setFinish(prev => !prev)}
      >
        <PassedTest setFinish={setFinish} />
      </CustomModal>
      {isTest && <div className="full-test">
        <TestPassing {...activeTest} index={activeIndex} length={test.stimulus.length} setTest={setNewTest} />
      </div>}
    </div>
  );
};

export default Test;