import React from 'react';
import {Grid} from "@mui/material";
import bgImage from "../../assets/images/nofile.svg";

export const NoFile = ({children}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        height: "400px",
        width: "1000px",
        backgroundSize: "cover",
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        objectFit: "cover",
        borderRadius: "20px",
      }}>
      {children}
    </Grid>
  );
};