import axios from "axios";

const baseURL = "https://deepneuro.com.au/api";

const http = axios.create({
    baseURL: baseURL,
});

const setHeaders = (token) => {
    if (!token) return;
    return {
        headers: {
            Authorization: token ? `JWT ${token}` : "",
        },
    };
};

http.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('auth'))?.access
    if (token) {
        config.headers.Authorization = `JWT ${token}`
    }
    return config;
});

http.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && error.config && !error.config._isRetry && error.config?.url !== "auth/users/token/" && error.config?.url !== "auth/users/create/") {
            originalRequest._isRetry = true;
            try {
                const state = JSON.parse(localStorage.getItem("auth"))
                const response = await axios.get(`${baseURL}/auth/users/token/refresh`, setHeaders(state.data.refresh))
                localStorage.setItem('state', {
                    ...state, authReducer: {
                        ...state.authReducer,
                        data: response.data
                    }
                });
                return http.request(originalRequest);
            } catch (e) {
                localStorage.removeItem("state")
                //window.location.reload();
            }
        }
        throw error;
    }
);

const Api = {
    getData: (url) => http.get(url),
    delete: (url, body) => http.delete(url, { data: body}),
    getDataById: (url, id) => http.get(url),
    postData: (url, body) => http.post(url, body),
    putData: (url, content) => http.put(url, content),
    patchData: (url, content) => http.patch(url, content),
};

export default Api;
