import React from 'react';

export const TestSuccessIcon = () => {
  return (
    <svg width="78" height="79" viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1028_4365)">
        <path d="M39 70.4265C57.2254 70.4265 72 55.5818 72 37.2699C72 18.958 57.2254 4.11328 39 4.11328C20.7746 4.11328 6 18.958 6 37.2699C6 55.5818 20.7746 70.4265 39 70.4265Z" fill="#1998A7"/>
      </g>
      <g filter="url(#filter1_d_1028_4365)">
        <path d="M37.5612 53.1558C36.9071 54.1047 35.5885 54.3059 34.6813 53.5953L22.3184 43.912C21.452 43.2333 21.2967 41.9822 21.9708 41.1123L23.9569 38.5496C24.636 37.6734 25.8983 37.5167 26.771 38.2003L33.0962 43.1545C34.0035 43.8651 35.3221 43.6639 35.9762 42.715L49.4423 23.1782C50.0714 22.2656 51.3226 22.0386 52.2321 22.6721L54.8835 24.5187C55.7868 25.1478 56.0119 26.3886 55.3872 27.295L37.5612 53.1558Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_1028_4365" x="0" y="0.113281" width="78" height="78.3135" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1028_4365"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1028_4365" result="shape"/>
        </filter>
        <filter id="filter1_d_1028_4365" x="15.5508" y="18.3135" width="46.1895" height="43.707" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1028_4365"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1028_4365" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
};