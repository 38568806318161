import '../../index.scss'
import React from 'react';
import Header from "../Header";
import Dashboard from "../Dashboard";

const Layout = ({children}) => {
  return (
    <>
      <>
        <Header />
        <Dashboard />
      </>
      <div className='layout-wrap'>
          {children}
      </div>
    </>
  );
};

export default Layout;