import React from 'react';
import {TestSuccessIcon} from "../../../assets/images/SVG/TestSuccessIcon";
import {Grid, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";

export const PassedTest = ({setFinish}) => {
  const history = useHistory();
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" className="p-2"
          style={{width: 360, height: 400, boxSizing: "border-box"}}>

      <Grid item container xs={12} justifyContent="center">
        <TestSuccessIcon/>
      </Grid>
      <Typography variant="h6" className="" style={{color: "#1998A7"}}>
        Test is successfully done!
      </Typography>

      <Grid item container className="text-center" style={{color: "#443E3E"}}>
        <Grid item xs={12}>
          Thank you!
        </Grid>
        <Grid item xs={12}>
          Test result will be available later.
        </Grid>
        <Grid item xs={12}>
          You can check it in The <strong>Test Result</strong> Page.
        </Grid>
      </Grid>

      <Grid item container xs={12} gap={2} justifyContent="center" className="py-2">
        <button className="btn-blue px-4 py-2" onClick={() => history.push('/tests-list')}>
          Back to tests
        </button>
        <button className="delete-blue px-4 py-2" onClick={() => history.push("/results")}>
          Got it!
        </button>
      </Grid>
    </Grid>
  );
};